//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ErrModule',
  data () {
    return {
      errorgreen: { src: require('@/assets/img/404.svg'), alt: '404', width: 160, heigth: 60 }
    }
  },
  head () {
    return {
      title: this.$t('not_found_page.pageNotFound')
    }
  }
}
