//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LazyHydrate from 'vue-lazy-hydration'
import projectData from '~/static/config/project.json'
import AxiApi from '~/lib/axi'
import global from '~/mixins/global.js'
import * as textFunctions from '~/mixins/text.functions.js'
import * as cssFunctions from '~/mixins/css.functions.js'
import { customPageView } from '~/lib/gtm'
import { filtersUrlIsIndexable } from '~/lib/seo'

// const Component = () => import('~/components/Component.vue')
export default {
  components: {
    LazyHydrate
  },
  mixins: [global],
  middleware: ['Filters'],
  props: {
    prodTypeId: {
      type: String,
      default: ''
    }
  },
  async asyncData ({ params, route, app, store, $axios, $config, $md5, error }) {
    try {
      // Params & Props
      const routeProps = route.matched[0].props.default
      const TypeId = routeProps.prodTypeId
      let productType = Object.values(projectData.contents.product_types).find(
        pt => pt.id === routeProps.prodTypeId
      )
      if (!productType) {
        productType = Object.values(projectData.contents.product_types_marketplaces).find(
          pt => pt.id === routeProps.prodTypeId
        )
        if (productType) {
          productType.marketplace = true
        }
      } else {
        productType.marketplace = false
      }
      if (productType && productType.filters) {
        productType.filters = productType.filters.map((filter) => {
          if (filter.share && projectData.filters_share && projectData.filters_share[filter.share]) {
            filter = { ...projectData.filters_share[filter.share], ...filter }
          }
          return filter
        })
        productType.filters.filter((filter) => {
          return filter
        })
      }

      // Busqueda texto
      let querySearch = ''
      if (typeof route.query.q !== 'undefined' && route.query.q !== '') {
        querySearch = route.query.q
      }

      // Ordenes
      let order = ''
      if (route.query.order) {
        const val = route.query.order.split('_')
        order = `&order=${val[0]}&direction=${val[1]}`
      }

      // Zones & Heritages
      const ax = new AxiApi($config.api)
      const call =
        (TypeId && `${$config.static}/json/zones/products/${TypeId}/zones.json`) ||
        `${$config.static}/json/zones/products/zones.json`
      const landingData = await $axios.get(call)
      await ax.createComponentData(landingData.data, store)

      // Breadcrumb
      let breadcrumb = []
      breadcrumb = breadcrumb.concat(routeProps.breadcrumb)
      breadcrumb.push({
        text: app.i18n.t('price_list.outlet')
      })

      // Get API data
      let page = 1
      const filters = {}
      const filtersSEO = {}
      let filters2api = ''
      const filters2seo = {}
      let filtroprice = false
      const selectedBrands = []
      const selectedStores = []
      if (params.tienda) {
        filters.store = [params.tienda]
        const pt = params.tienda.split(',')
        pt.forEach((el) => {
          const selectedStore = store.state.stores.find((b) => {
            return b.id === el
          })
          if (selectedStore) {
            selectedStores.push(selectedStore)
          }
        })
      }
      let filtersUrl = params.pathMatch
      if (filtersUrl.match(/^(.*?\/)?([0-9]+)\/?$/)) {
        const pathMatches = filtersUrl.match(/^(.*?\/)?([0-9]+)\/?$/)
        page = pathMatches[2]
        filtersUrl = pathMatches[1] !== undefined ? pathMatches[1] : ''
      }

      try {
        filtersUrl.split('/').forEach((element) => {
          if (element) {
            const matches = element.match(/^([^-]+)-(.*?)$/)
            const filtroId = matches[1]
            let filtroValues = matches[2].split(',')
            const filtroValuesSEO = []
            const filtroConfig = productType.filters.find((f) => {
              return (f.url || f.name) === filtroId
            })
            if (filtroConfig && filtroConfig.values) {
              const newFiltroValues = []
              filtroValues.forEach((v) => {
                newFiltroValues.push(
                  filtroConfig.values.find(fv => (fv.url || fv.name) === v).id
                )
                const filtroValue = filtroConfig.values.find(fv => (fv.url || fv.name) === v)
                if (filtroValue) {
                  filtroValuesSEO.push(filtroValue.seo || filtroValue.name)
                }
              })
              filtroValues = newFiltroValues
            }
            if (filtroConfig.id === 'brand') {
              filtroValues.forEach((el) => {
                if (el) {
                  const selectedBrand = store.state.brands.find((b) => {
                    return b.id === el
                  })
                  if (selectedBrand) {
                    selectedBrands.push(selectedBrand)
                  }
                }
              })
            }
            filters[filtroConfig.id] = filtroValues.join(',')
            if (!filtroprice) {
              filtroprice = filtroConfig.id === 'price'
            }
            if (filtroValuesSEO.length > 0) {
              filtersSEO[filtroConfig.id] = filtroValuesSEO
            }
          }
        })
      } catch (err) {
        return error({ statusCode: 404, message: err.message })
      }
      // Ordenando filtros
      if (productType && productType.filters) {
        productType.filters.forEach((f) => {
          if (typeof filters[f.id] !== 'undefined') {
            filters2api += '/' + f.id + '-' + filters[f.id]
          }
        })
      }

      if (querySearch !== '') {
        filters.text = `'${querySearch}'`
      }

      if (productType && productType.filters) {
        if (selectedStores.length > 0) {
          filters.store = selectedStores
            .map(function (elem) {
              return elem.nombre
            })
            .join(',')
        }
        if (selectedBrands.length > 0) {
          filters.brand = selectedBrands
            .map(function (elem) {
              return elem.name
            })
            .join(',')
        }

        productType.filters.forEach((filter) => {
          if (filters[filter.id]) {
            filtersSEO[filter.id] = (filter.seo || '{value}').replace('{value}', (filtersSEO[filter.id] && filtersSEO[filter.id].join(', ')) || filters[filter.id])
          } else if (
            filter.default?.prices?.seo &&
            (
              !filter.default?.prices?.filters ||
              (
                ((filter.default?.prices.filters === 'no') && (Object.keys(filters).length === 0)) ||
                ((filter.default?.prices.filters === 'yes') && (Object.keys(filters).length > 0))
              )
            )

          ) {
            filtersSEO[filter.id] = filter.default.prices.seo
          }
        })
      }

      let productTypeMetas = productType.metas.prices
      if (productType?.metas?.prices) {
        let filtersMetas = false
        Object.entries(filters).forEach(([filter, filterValue]) => {
          const filterObj = productType.filters.find(ptFilter => ptFilter.id === filter)
          if (filterObj) {
            const filterValueObj = filterObj.values && filterObj.values.find(ptFilterValue => ptFilterValue.id === filterValue)
            if (filterValueObj && filterValueObj.metas?.prices) {
              filtersMetas = ((filtersMetas === false) && { ...productType.metas.prices, ...filterValueObj.metas.prices }) || false
            }
          }
        })
        productTypeMetas = filtersMetas || productType.metas.prices

        if (productTypeMetas.filters) {
          Object.keys(productTypeMetas.filters).forEach((filtersID) => {
            filters2seo[filtersID] = ''
            productTypeMetas.filters[filtersID].forEach((filterID) => {
              const filter2seo = filtersSEO[filterID] || filters[filterID] || ''
              filters2seo[filtersID] += (filter2seo && (' ' + filter2seo)) || ''
            })
            filters2seo[filtersID] = filters2seo[filtersID].trim().replace(/,/g, ', ').replace(/ +/, ' ')
            filters2seo[filtersID] = filters2seo[filtersID] && (' ' + filters2seo[filtersID])
          })
        }
      }

      const query = `${$config.api}/app/v1/prices/list?_p=${$config.id}&products_type=${routeProps.prodTypeId}&filters=${filters2api}&text=${querySearch}&page=${page}${order}&scope=precios`
      const code = $md5(query)
      if (
        typeof store.state.lists[code] === 'undefined' ||
        Object.keys(store.state.lists[code]).length === 0
      ) {
        await store.dispatch('loadLists', query)
      }
      const productPrices = store.state.lists[code]

      // Textos
      let textos = {}
      const notfilter = true

      textos = {
        pagename: projectData.name,
        h1: productTypeMetas.h1 && textFunctions.textNormalize(
          productTypeMetas.h1
            .replace(/{filters:(.+?)}/g, function (_str, filterGroup) {
              return (filters2seo[filterGroup] && filters2seo[filterGroup].replace('-', ' ')) || ''
            })
            .replace(/grados/, 'º')
            .replace(/mas/g, '+')
        ),
        h2: productTypeMetas.h2 && textFunctions.textNormalize(
          productTypeMetas.h2.replace(
            /{year}/,
            new Date().getFullYear()
          )
        ),
        title: '',
        description: productTypeMetas.description && textFunctions.textNormalize(
          productTypeMetas.description
            .replace(/{filters:(.+?)}/g, function (_str, filterGroup) {
              return (filters2seo[filterGroup] && filters2seo[filterGroup].toLowerCase()) || ''
            })
        )
      }

      if (productTypeMetas.title && productTypeMetas.title.includes('{pagina}')) {
        textos.title = textFunctions.textNormalize(
          productTypeMetas.title
            .replace(/{pagename}/, projectData.name)
            .replace(/{filters:(.+?)}/g, function (_str, filterGroup) {
              return filters2seo[filterGroup] || ''
            })
            .replace(
              '{pagina}',
              page > 1 ? `, ${app.i18n.t('price_list.page').toLowerCase()} ${page}` : ''
            )
        )
      } else {
        textos.title = (productTypeMetas.title && textFunctions.textNormalize(
          productTypeMetas.title
            .replace(/{pagename}/, projectData.name)
            .replace(/{filters:(.+?)}/g, function (_str, filterGroup) {
              return filters2seo[filterGroup] || ''
            })
        )) || ''
        textos.title =
          textFunctions.textNormalize(textos.title.slice(0, textos.title.indexOf('|') - 1) +
            (page > 1 ? `, ${app.i18n.t('price_list.page').toLowerCase()} ${page} ` : '') +
            textos.title.slice(textos.title.indexOf('|') - 1))
      }

      let structuredDataPrices = {}
      if (productPrices) {
        // Structured Data
        if (productPrices?.pagination?.contents) {
          structuredDataPrices = {
            '@context': 'http://schema.org',
            '@type': 'ItemList',
            numberOfItems: productPrices.pagination.contents,
            itemListElement: [],
            name: app.i18n.t('price_list.page_title').replace('{seo}', productType.seo || productType.name)
          }
        }
        if (productPrices?.contents) {
          productPrices.contents.forEach(function (price, i) {
            const item = {
              '@type': 'ListItem',
              position: `${i + 1}`,
              url: `${$config.domain}/${price.link.code}`,
              image: `${price.photo}`,
              name: `${price.name}`
            }
            structuredDataPrices.itemListElement.push(item)
          })
        }
      }

      // Metas, Head & Header
      const header = {
        h1: textos.h1.charAt(0).toUpperCase() + textos.h1.slice(1),
        h2: textos.h2.charAt(0).toUpperCase() + textos.h2.slice(1)
      }
      const head = {
        title: textos.title,
        meta: [
          {
            hid: 'title',
            name: 'title',
            content: textos.title
          },
          {
            hid: 'description',
            name: 'description',
            content: textos.description
          },
          {
            hid: 'og:site_name',
            name: 'og:site_name',
            content: `${$config.siteName}`
          },
          {
            hid: 'og:url',
            name: 'og:url',
            content: `${$config.domain}${route.path}`
          },
          {
            hid: 'og:description',
            name: 'og:description',
            content: textos.description
          },
          {
            hid: 'og:type',
            name: 'og:type',
            content: 'website'
          },
          {
            hid: 'og:title',
            name: 'og:title',
            content: textos.title
          },
          {
            hid: 'og:locale',
            property: 'og:locale',
            content: `${$config.locale}`
          }
        ],
        script: [{ type: 'application/ld+json', json: structuredDataPrices }],
        link: [
          {
            rel: 'canonical',
            href: `${$config.domain}${route.path}`
          },
          {
            rel: 'preload',
            href:
              (productPrices &&
                productPrices.contents &&
                productPrices.contents[0]?.photo) ||
              ''
          }
        ]
      }

      const urlIndexable = (Object.keys(route.query).length === 0) && filtersUrlIsIndexable(productPrices?.filters?.active, productType, 'prices')

      if (!urlIndexable) {
        head.meta.push({
          hid: 'robots',
          name: 'robots',
          content: 'noindex, follow'
        })
      }

      const storesFilter = productPrices.filters.view.find(f => f.id === 'store')
      const storesTotal = !storesFilter?.active
        ? storesFilter?.values.length
        : storesFilter?.values.filter(f => f.active).length

      const extraModulesBottom = []
      if (productPrices.moduleText && Object.keys(productPrices.moduleText).length > 0) {
        const RewriteText = {
          template: productPrices.moduleText.ConfigurationFile.template,
          data: productPrices.moduleText.ConfigurationFile.content.info
        }
        extraModulesBottom.push(RewriteText)
      }

      const sorting = []
      Object.keys(projectData.contents.product_types.products.metas.prices.sorts).forEach((sort) => {
        sorting.push({
          value: sort,
          text: projectData.contents.product_types.products.metas.prices.sorts[sort].text
        })
      })

      const zonesContent = ax.get()

      if (!app.$isAMP) {
        head.style = await cssFunctions.criticals(route.path, 'price_list', {
          ABC_0: zonesContent.GlobalData?.zones?.ABC_0 || 0
        })
      }

      return {
        productType,
        breadcrumb,
        prices: productPrices,
        testAB: productPrices.test,
        head,
        header,
        location: filtersUrl,
        querySearch,
        order: route.query.order ? route.query.order : 'points_asc',
        zones: zonesContent,
        withheader: notfilter,
        storesTotal: storesTotal || 0,
        sorting,
        extraModulesBottom,
        outletPath: (productType.paths.prices ? '/' + productType.paths.prices : '') + '/' + productType.paths.prices_all + '/',
        urlIndexable
      }
    } catch (err) {
      return error({ statusCode: 404, message: err.message })
    }
  },
  data () {
    return {
      bestOffer: false,
      order: null,

      // imagenes
      fechaabajogris: {
        width: 10,
        height: 24,
        class: 'icon',
        alt: 'order',
        src: require('@/assets/img/icons/icon-chevron-down-grey.svg')
      },
      chevronDown: {
        width: 10,
        height: 24,
        class: 'icon',
        alt: 'order',
        src: require('@/assets/img/icons/icon-chevron-down-grey.svg')
      },
      filters: []
    }
  },
  head () {
    return this.head
  },
  computed: {
    brands () {
      let filteredBrands = []
      filteredBrands = this.$store.state.brands.filter((brand) => {
        return brand.name === this.brand
      })
      return filteredBrands
    },
    stores () {
      let filteredStores = []
      filteredStores = this.$store.state.stores.filter((store) => {
        return store.name === this.store
      })
      return filteredStores
    }
  },
  beforeMount () {
    if (this.prices?.filters?.view) {
      this.filters = JSON.parse(JSON.stringify(this.prices.filters.view))

      this.filters.map((filter) => {
        const filterMapped = filter

        if (filterMapped.values) {
          filterMapped.values.map((value) => {
            const valueMapped = value

            valueMapped.finalUrl = this.create_filter_url(
              filter.id,
              value.id,
              this.prices.filters.url,
              this.productType,
              'prices',
              '',
              this.$route.query
            )

            const customFiltersActive = JSON.parse(JSON.stringify(this.prices.filters.active || {}))

            if (customFiltersActive[filter.id]) {
              Object.keys(customFiltersActive).forEach((filterID) => {
                if (filterID === filter.id) {
                  if (customFiltersActive[filterID].includes(value.id)) {
                    customFiltersActive[filterID] = customFiltersActive[filterID].filter((filterValue) => {
                      return filterValue !== value.id
                    })
                  } else {
                    customFiltersActive[filterID].push(value.id)
                  }
                }
              })
            } else {
              customFiltersActive[filter.id] = [value.id]
            }

            valueMapped.nofollow = (Object.keys(this.$route.query).length > 0) || !filtersUrlIsIndexable(customFiltersActive, this.productType, 'prices')

            return valueMapped
          })
        }

        return filterMapped
      })
    }
  },
  mounted () {
    customPageView.apply(this, [this.head.title])
  },
  methods: {
    changeOrder (val) {
      const pathArr = this.$route.fullPath.match(/(^[^?]+)\??(.+$)?/)
      let path = pathArr[1]
      let qryStr = pathArr[2] || ''
      // Tratar path
      if (path.match(/^(.*?\/)?([0-9]+)\/?$/)) {
        path = path.match(/^(.*?\/)?([0-9]+)\/?$/)[1]
      }
      // Tratar qryStr
      if (qryStr !== '') {
        if (qryStr.match(/order=[^&?]+/)) {
          qryStr = qryStr.replace(/order=[^&?]+/, `order=${val}`)
        } else {
          qryStr += '&order=' + val
        }
      }
      if (qryStr === '') {
        qryStr = 'order=' + val
      }
      const newUrl = projectData.domains.www.base + path + '?' + qryStr
      window.location = newUrl.replace('//', '/')
    },
    capitalize (txt) {
      txt = txt.trim()
      return txt.charAt(0).toUpperCase() + txt.slice(1)
    },
    specialFormat (txt) {
      if (txt.includes('Máquinas de fitness categoría')) {
        return txt.split('categoría ')[1]
      } else {
        return txt
      }
    }
  }
}
