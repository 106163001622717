import { render, staticRenderFns } from "./_.vue?vue&type=template&id=0c98f2f6&"
import script from "./_.vue?vue&type=script&lang=js&"
export * from "./_.vue?vue&type=script&lang=js&"
import style0 from "./_.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppDynamicComponent: require('/furanet/sites/runnea.com/web/htdocs/nuxt/components/AppDynamicComponent.vue').default,AppCardsCarousel: require('/furanet/sites/runnea.com/web/htdocs/nuxt/components/AppCardsCarousel.vue').default})
