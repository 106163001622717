//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import AxiApi from '~/lib/axi'
import projectData from '~/static/config/project.json'
import global from '~/mixins/global.js'
import { VBPopover } from 'bootstrap-vue'
import * as cssFunctions from '~/mixins/css.functions.js'
import { customPageView } from '~/lib/gtm'

Vue.config.silent = true
export default {
  directives: {
    'b-popover': VBPopover
  },
  mixins: [global],
  async asyncData ({ params, route, app, store, error, $axios, $config, $md5 }) {
    try {
      // Params & Props
      const routeProps = route.matched[0].props.default
      const TypeId =
        routeProps && routeProps.prodTypeId > 0 ? routeProps.prodTypeId : 0
      const TypeConfig = Object.values(projectData.contents.product_types).find(
        (pt) => pt.id === TypeId
      )

      // Zones & Heritages
      const ax = new AxiApi($config.api)
      let zones
      try {
        zones = await $axios.get(
          `${$config.static}/json/zones/comparator/${TypeId}/zones.json`
        )
      } catch (err) {
        try {
          zones = await $axios.get(
            $config.static + '/json/zones/comparator/zones.json'
          )
        } catch (err) {
          return error({ statusCode: 404, message: err.message })
        }
      }
      await ax.createComponentData(zones.data, store)

      // Load Store
      if (!store.state.prodsPerType[TypeId]) {
        await store.dispatch('loadProductsPerType', TypeId)
      }
      const products = store.state.prodsPerType[TypeId]
      // Get products info
      function arrayToObject (prd) {
        const restore = {}
        for (let i = 0; i < Object.keys(prd).length; i++) {
          restore[prd[i].id] = prd[i].values
        }
        return restore
      }
      function objectToArray (prd) {
        const restore = []
        for (const key in prd) {
          if (prd[key] === undefined) {
            prd[key] = ['-']
          }
          const element = {
            id: key,
            values: prd[key]
          }
          restore.push(element)
        }
        return restore
      }
      function replenish (template, prd) {
        const restore = {}
        for (const key in template) {
          restore[key] = prd[key]
        }
        return restore
      }
      const cmpPrds = []
      let prdData1, prdData2
      if (params.prd1 && params.prd1 !== '') {
        const prdPath = products.filter((p) => p.content_id === params.prd1)[0]
          .path
        const query = `${$config.static}/${prdPath}`
        const code = $md5(query)
        if (
          typeof store.state.lists[code] === 'undefined' ||
          Object.keys(store.state.lists[code]).length === 0
        ) {
          await store.dispatch('loadLists', query)
        }
        prdData1 = store.state.lists[code]
      }
      if (params.prd2 && params.prd2 !== '') {
        const prdPath = products.filter((p) => p.content_id === params.prd2)[0]
          .path
        const query = `${$config.static}/${prdPath}`
        const code = $md5(query)
        if (
          typeof store.state.lists[code] === 'undefined' ||
          Object.keys(store.state.lists[code]).length === 0
        ) {
          await store.dispatch('loadLists', query)
        }
        prdData2 = store.state.lists[code]
      }
      const merged = {
        ...arrayToObject(prdData1.features),
        ...arrayToObject(prdData2.features)
      }
      const featuresTemplate = Object.keys(merged).reduce(
        (a, v) => ({ ...a, [v]: ['-'] }),
        {}
      )
      prdData1.allFeatures = objectToArray(
        replenish(featuresTemplate, arrayToObject(prdData1.features))
      )
      prdData2.allFeatures = objectToArray(
        replenish(featuresTemplate, arrayToObject(prdData2.features))
      )
      cmpPrds.push(prdData1)
      cmpPrds.push(prdData2)
      const comparisonLink = `${route.path}`.match(
        /(\d+)\/(\d+)\/([^"]+)-vs-([^"]+).html/
      )
      if (comparisonLink[1] > comparisonLink[2]) {
        route.path = `${TypeConfig.paths.home}/${
          TypeConfig.paths.comparison ||
          projectData.contents.product_types.products.paths.comparison
        }/${comparisonLink[2]}/${comparisonLink[1]}/${comparisonLink[4]}-vs-${
          comparisonLink[3]
        }.html`
      }
      // Metas & Head
      const dinamicSeo = `${Array.from(
        cmpPrds,
        (p) => `${p.brand.name} ${p.name}`
      ).join(' vs ')}`
      const header = {
        h1: app.i18n
          .t('comparison_page.page_title')
          .replace(/{seo}/g, dinamicSeo)
      }
      const head = {
        title: `${app.i18n
          .t('comparison_page.meta_title')
          .replace(/{seo}/g, dinamicSeo)} | ${projectData.name}`,
        meta: [
          {
            hid: 'title',
            name: 'title',
            content: `${app.i18n
              .t('comparison_page.meta_title')
              .replace(/{seo}/g, dinamicSeo)} | ${projectData.name}`
          },
          {
            hid: 'description',
            name: 'description',
            content: app.i18n
              .t('comparison_page.meta_description')
              .replace(
                /{seo}/g,
                `${TypeConfig.name.toLowerCase()} ${dinamicSeo}`
              )
          }
        ],
        link: [
          {
            rel: 'canonical',
            href: `${$config.domain}${route.path}`
          }
        ]
      }

      if (Object.keys(route.query).length > 0) {
        head.meta.push({
          hid: 'robots',
          name: 'robots',
          content: 'noindex, follow'
        })
      }

      const zonesContent = ax.get()

      if (!app.$isAMP) {
        head.style = await cssFunctions.criticals(route.path, 'comparison_page', {
          ABC_0: zonesContent.GlobalData?.zones?.ABC_0 || 0
        })
      }

      return {
        zones: zonesContent,
        comparison: cmpPrds,
        head,
        header,
        products: products.filter((p) => p.type_id === TypeId)
      }
    } catch (err) {
      return error({ statusCode: 404, message: err.message })
    }
  },
  data () {
    return {
      slide: 0,
      sliding: null,
      bottomPrices: false,
      productphotolarge: { class: 'card-img', width: 200, height: 200 }
    }
  },
  head () {
    return this.head
  },
  mounted () {
    customPageView.apply(this, [this.head.title])

    const limitTop = this.$refs['bottomPrices-ref'].getBoundingClientRect().top
    window.addEventListener('scroll', () => {
      this.bottomPrices = document.documentElement.scrollTop >= limitTop
    })
  },
  methods: {
    onSlideStart (slide) {
      this.sliding = true
    },
    onSlideEnd (slide) {
      this.sliding = false
    },
    scrollTo (element, scrollPixels, duration) {
      const scrollPos = element.scrollLeft
      // Condition to check if scrolling is required
      if (
        !(
          (scrollPos === 0 || scrollPixels > 0) &&
          (element.clientWidth + scrollPos === element.scrollWidth ||
            scrollPixels < 0)
        )
      ) {
        // Get the start timestamp
        const startTime =
          'now' in window.performance
            ? performance.now()
            : new Date().getTime()

        function scroll (timestamp) {
          // Calculate the timeelapsed
          const timeElapsed = timestamp - startTime
          // Calculate progress
          const progress = Math.min(timeElapsed / duration, 1)
          // Set the scrolleft
          element.scrollLeft = scrollPos + scrollPixels * progress
          // Check if elapsed time is less then duration then call the requestAnimation, otherwise exit
          if (timeElapsed < duration) {
            // Request for animation
            window.requestAnimationFrame(scroll)
          }
        }
        // Call requestAnimationFrame on scroll function first time
        window.requestAnimationFrame(scroll)
      }
    },
    scrollLeft () {
      const carousel = this.$refs.carousel
      const card = carousel.querySelector('.card')
      const width = card.offsetWidth
      const margin =
        parseFloat(window.getComputedStyle(card).marginLeft) +
        parseFloat(window.getComputedStyle(card).marginRight)
      this.scrollTo(carousel, (width + margin) * -2, 200)
    },
    scrollRight () {
      const carousel = this.$refs.carousel
      const card = carousel.querySelector('.card')
      const width = card.offsetWidth
      const margin =
        parseFloat(window.getComputedStyle(card).marginLeft) +
        parseFloat(window.getComputedStyle(card).marginRight)

      this.scrollTo(carousel, (width + margin) * 2, 200)
    }
  }
}
