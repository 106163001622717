//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import { mapState } from 'vuex'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import LazyHydrate from 'vue-lazy-hydration'
import AxiApi from '~/lib/axi'
import projectData from '~/static/config/project.json'
import global from '~/mixins/global.js'
import * as cssFunctions from '~/mixins/css.functions.js'
import { customPageView } from '~/lib/gtm'

Vue.config.silent = true
export default {
  components: {
    VueTypeaheadBootstrap,
    LazyHydrate
  },
  mixins: [global],
  async asyncData ({ route, app, store, error, $axios, $config }) {
    // Params & Props
    const routeProps = route.matched[0].props.default
    const TypeId = (routeProps && routeProps.prodTypeId > 0) ? routeProps.prodTypeId : 0
    const TypeConfig = Object.values(projectData.contents.product_types).find(p => p.id === TypeId)

    // Zones & Heritages
    const ax = new AxiApi($config.api)
    let zones
    try {
      zones = await $axios.get(`${$config.static}/json/zones/comparator/${TypeId}/zones.json`)
    } catch (err) {
      try {
        zones = await $axios.get($config.static + '/json/zones/comparator/zones.json')
      } catch (err) {
        return error({ statusCode: 404, message: err.message })
      }
    }

    await ax.createComponentData(zones.data, store)

    // Load Store
    if (!store.state.productsLoaded) {
      await store.dispatch('loadProducts')
    }
    const products = store.state.products

    // Metas & Head
    const dinamicSeo = TypeConfig.name.toLowerCase()
    const header = {
      h1: app.i18n.t('comparator_page.page_title').replace(/{seo}/g, dinamicSeo)
    }
    const head = {
      title: `${app.i18n.t('comparator_page.meta_title').replace(/{seo}/g, dinamicSeo)} | ${projectData.name}`,
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: `${app.i18n.t('comparator_page.meta_title').replace(/{seo}/g, dinamicSeo)} | ${projectData.name}`
        },
        {
          hid: 'description',
          name: 'description',
          content: app.i18n.t('comparator_page.meta_description').replace(/{seo}/g, dinamicSeo).replace(/{seo_the_best}/g, TypeConfig.texts.the_best)
        }
      ]
    }

    if (Object.keys(route.query).length > 0) {
      head.meta.push(
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex, follow'
        }
      )
    }

    const zonesContent = ax.get()

    if (!app.$isAMP) {
      head.style = await cssFunctions.criticals(route.path, 'comparator_page', {
        ABC_0: zonesContent.GlobalData?.zones?.ABC_0 || 0
      })
    }

    return {
      zones: zonesContent,
      TypeConfig,
      head,
      header,
      products: products.filter(
        p => p.type_id === TypeId
      )
    }
  },
  data () {
    return {
      browserText: '',
      // Imagenes
      dataphoto: { class: 'imageboot', alt: 'finder2', width: 25, heigth: 25 },
      productphotolarge: { class: 'card-img', width: 300, height: 300 }
    }
  },
  head () {
    return this.head
  },
  computed: {
    ...mapState('comparator', { comparison: state => state.list })
  },
  mounted () {
    (async () => {
      customPageView.apply(this, [this.head.title])
      await this.$store.dispatch('comparator/init')
    })()
  },
  methods: {
    addItem (product) {
      if (this.comparison.length === 1) {
        this.browserText = ''
        this.$store.dispatch('comparator/add', product)
        const ready = setInterval(() => {
          if (this.comparison.length === 2) {
            this.comparation(this.comparison[0], this.comparison[1])
            clearInterval(ready)
          }
        }, 100)
      } else {
        this.browserText = ''
        this.$store.dispatch('comparator/add', product)
      }
    },
    removeItem (product) {
      this.$store.dispatch('comparator/remove', product)
    },
    setPage () {
      const productsTitle = []

      this.comparison.forEach((product) => {
        if (product.brand?.name && product.name) {
          productsTitle.push(product.brand.name + ' ' + product.name)
        }
      })

      const title = productsTitle.length === 2 ? `${this.$t('comparison_page.meta_title').replace(/{seo}/g, productsTitle.join(' vs '))} | ${projectData.name}` : ''

      const productsUrl = []

      let productsType = false

      this.comparison.forEach((product) => {
        if (!productsType && product.type_id) {
          productsType = product.type_id
        }
        const productPath = product.path ? product.path.match(/^\/json\/products\/(?:[0-9]+)\/(.+?)\/([0-9]+)\/index.json$/) : false
        if (product.content_id && product.type_id && (product.type_id === productsType) && productPath) {
          productsUrl.push({
            id: parseInt(product.content_id, 10),
            name: productPath[1].replace('/', '-')
          })
        }
      })

      productsUrl.sort((a, b) => {
        return a.id - b.id
      })

      const productsTypeObj = Object.values(projectData.contents.product_types).find(p => p.id === productsType)
      const url = productsTypeObj && (productsUrl.length === 2)
        ? '/' + productsTypeObj.paths.home + '/' + (productsTypeObj.paths.comparison || projectData.contents.product_types.products.paths.comparison) + '/' + productsUrl.map((product) => {
          return product.id
        }).join('/') + '/' + productsUrl.map((product) => {
          return product.name
        }).join('-vs-') + '.html'
        : ''

      return {
        title,
        url,
        clipboard: true
      }
    },
    comparation (prdData1, prdData2) {
      const prdAllData1 = prdData1
      const prdAllData2 = prdData2
      this.removeItem(prdData1)
      this.removeItem(prdData2)
      function arrayToObject (prd) {
        const restore = {}
        for (let i = 0; i < Object.keys(prd).length; i++) {
          restore[prd[i].id] = prd[i].values
        }
        return restore
      }
      function objectToArray (prd) {
        const restore = []
        for (const key in prd) {
          if (prd[key] === undefined) {
            prd[key] = ['-']
          }
          const element = {
            id: key,
            values: prd[key]
          }
          restore.push(element)
        }
        return restore
      }
      function replenish (template, prd) {
        const restore = {}
        for (const key in template) {
          restore[key] = prd[key]
        }
        return restore
      }
      const merged = { ...arrayToObject(prdAllData1.features), ...arrayToObject(prdAllData2.features) }
      const featuresTemplate = Object.keys(merged).reduce((a, v) => ({ ...a, [v]: ['-'] }), {})
      prdAllData1.allFeatures = objectToArray(replenish(featuresTemplate, arrayToObject(prdAllData1.features)))
      prdAllData2.allFeatures = objectToArray(replenish(featuresTemplate, arrayToObject(prdAllData2.features)))
      this.$store.dispatch('comparator/add', prdAllData1)
      this.$store.dispatch('comparator/add', prdAllData2)
    }
  }
}
