//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import global from '~/mixins/global.js'
import projectData from '~/static/config/project.json'
import { openAuthRequired } from '~/lib/auth'
export default {
  name: 'ModuleMenu',
  mixins: [global],
  inheritAttrs: false,
  props: {
    data: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    elements: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    generic: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    zone: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      searchfocus: false,
      showFinder: false,
      products: [],
      iconMenu: require('~/assets/img/icons/icon-hamburger-menu.svg'),
      registro: false,
      projectData,
      logo: {
        height: 38,
        width: 183,
        alt: projectData.name
      },
      runneaacademy: {
        height: 50,
        width: 50,
        src: require('@/assets/img/icons/icon-runnea-academy.svg')
      },
      currentTopPadding: null,
      ind: 0
    }
  },
  mounted () {
    this.overflow(false)
    document.getElementById('header').style.background = window.getComputedStyle(
      document.getElementsByClassName('main-content')[0],
      null
    ).background
  },
  methods: {
    abrirSubmenu (id, sidebar = false) {
      const menuItem = document.getElementById(id)
      menuItem.style.display === 'block'
        ? (menuItem.style.display = 'none')
        : (menuItem.style.display = 'block')

      menuItem.className === 'menu-sub3'
        ? document.querySelectorAll('.menu-sub3').forEach((ul) => {
          if (ul.id !== id) {
            ul.style.display = 'none'
          }
        })
        : document.querySelectorAll('.menu-sub1').forEach((ul) => {
          if (ul.id !== id) {
            ul.style.display = 'none'
          }
        })
      menuItem.addEventListener('mouseleave', () => {
        if (screen.width > 991) {
          if (!sidebar) {
            clearInterval(this.intervalo)
          }
          setTimeout(() => {
            menuItem.style.display = 'none'
          }, 500)
        }
      })
    },
    overflow (visible) {
      visible
        ? (document.getElementsByTagName('body')[0].style.overflow = 'hidden')
        : (document.getElementsByTagName('body')[0].style.overflow = 'visible')
    },
    login () {
      openAuthRequired.apply(this, [null, null, 'modal', 'cabecera'])
    },
    logout () {
      this.$auth.logout()
    },
    loadFinder () {
      this.showFinder = true
      if (!this.$store.state.productsLoaded) {
        this.$store.dispatch('loadProducts')
      }
    }
  }
}
