import project from '~/static/config/project.json'

export default {
  methods: {
    getProductsType (type) {
      return (
        Object.values(project.contents.product_types).find(
          p => Number(p.id) === Number(type)
        ) || false
      )
    },
    getPriceFeature (features, id) {
      const otp = features.find(f => f.id === id)
      return otp?.values || []
    },
    getProdTypeName (prodId) {
      const prodTypeConfig = this.getProductsType(prodId)
      return (prodTypeConfig && prodTypeConfig.name.toLowerCase()) || ''
    },
    setArticleLink (path) {
      let urlpath = ''
      let end = ''
      if (!path.match(/json/)) {
        path = `/json/galleries/${path}.json`
        end = '.html'
      }
      if (path.match(/^\/json\/galleries\/(.+?).json$/)) {
        urlpath = `${this.setGalleryLink(path)}${end}`
      } else {
        const pathMatches1 = path.match(/^\/json\/articles\/(.+?)\.index\.json$/)
        const pathMatches2 = path.match(/^\/json\/articles\/(.+?)index\.json$/)
        if (pathMatches1) {
          urlpath = `/${project.contents.articles.all.paths.home}/${pathMatches1[1]}.html`
        } else if (pathMatches2) {
          urlpath = `/${project.contents.articles.all.paths.home}/${pathMatches2[1]}`
        } else {
          urlpath = `/${project.contents.articles.all.paths.index || project.contents.articles.all.paths.home}/`
        }
      }
      return urlpath
    },
    setAuthorArticlesLink (id) {
      return `/${project.contents.articles.all.paths.home}/${project.contents.articles.all.paths.authors}/${id}/`
    },
    setAuthorReviewsLink (type, autorId) {
      const prodTypeConfig = this.getProductsType(type)
      return (
        (prodTypeConfig &&
          prodTypeConfig.id !== '0' &&
          '/' +
          prodTypeConfig.paths.home +
          '/' +
          project.contents.reviews.all.paths.home +
          '/' +
          project.contents.reviews.all.paths.authors +
          '/' +
          autorId +
          '/') ||
        '/' +
        project.contents.reviews.all.paths.home +
        '/' +
        project.contents.reviews.all.paths.authors +
        '/' +
        autorId +
        '/'
      )
    },
    setProdTypeBrandLink (brand, prodId) {
      const prodTypeConfig = this.getProductsType(prodId)
      return (
        (prodTypeConfig &&
          `/${prodTypeConfig.paths.home}/${prodTypeConfig.paths.products ||
          project.contents.product_types.products.paths.products
          }/${brand.id}/`) ||
        ''
      )
    },
    setGalleryLink (path) {
      const pathMatches = path.match(/^\/json\/galleries\/(.+?).json$/)
      return `/${project.contents.galleries.all.paths.home}/${pathMatches[1]}`
    },
    setLinkType (url, nv2 = true) {
      const domRegex = new RegExp(`^https?://${project.domains.www.domain}`)
      return nv2
        ? !url.match(/^https?:\/\//)
            ? 'INTERNO'
            : 'EXTERNO'
        : url.match(domRegex) || !url.match(/^https?:\/\//)
          ? 'INTERNO'
          : 'EXTERNO'
    },
    setLink (code, org = '') {
      const baseUrl = '/' + project.contents.redirects.all.paths.home + '/'
      let qryStr = `cod=${code}`
      if (org !== '') {
        qryStr += '&org=' + encodeURIComponent(org)
      }
      return baseUrl + '?' + qryStr
    },
    setDynamicShopLink (module, shop, code, pos = '', org = '', at = '') {
      const baseUrl = `/${module}/${shop}/${project.contents.redirects.all.paths.home}/`
      let qryStr = `cod=${code}`
      if (pos !== '') {
        qryStr += '&pos=' + encodeURIComponent(pos)
      }
      if (org !== '') {
        qryStr += '&org=' + encodeURIComponent(org)
      }
      if (at !== '') {
        qryStr += '&at=' + encodeURIComponent(at)
      }
      return baseUrl + '?' + qryStr
    },
    setNuxtLink (url) {
      const domRegex = new RegExp(`^https?://${project.domains.www.domain}`)
      return url.replace(domRegex, '')
    },
    setPriceLink (price, pos = '', org = '', at = '') {
      let finalUrl = '#'
      let baseUrl = `/${project.contents.redirects.all.paths.prices}/`
      baseUrl = baseUrl.replace('#store#', price.store?.id || price.id || 'void')
      let qryStr = `cod=${price.link?.track || price.track || ''}`
      if (pos !== '') {
        qryStr += '&pos=' + encodeURIComponent(pos)
      }
      if (org !== '') {
        qryStr += '&org=' + encodeURIComponent(org)
      }
      if (at !== '') {
        qryStr += '&at=' + encodeURIComponent(at)
      }
      if (qryStr !== '') {
        finalUrl = baseUrl + '?' + qryStr
      }
      return finalUrl
    },
    setPriceCardClickEvent (trackEvent, itemInfo) {
      localStorage.removeItem('trackEvent')
      localStorage.removeItem('itemInfo')
      localStorage.setItem('trackEvent', trackEvent)
      localStorage.setItem('itemInfo', JSON.stringify(itemInfo))
    },
    setProductLink (path) {
      let urlpath = '#'
      if (path) {
        const pathMatches = path.match(/^\/json\/products\/([0-9]+)(.+?)index.json$/)
        if (pathMatches !== null) {
          const prodTypeConfig = this.getProductsType(pathMatches[1])
          if (prodTypeConfig) {
            urlpath = `/${prodTypeConfig.paths.home}${pathMatches[2]}`
          }
        }
      }
      return urlpath
    },
    setComparisionLink (path1, path2) {
      let urlpath = '#'
      let pathMatches1
      let pathMatches11 = ''
      let pathMatches2
      let pathMatches21 = ''
      let prodTypeConfig = false
      if (path1) {
        pathMatches1 = path1.match(/^\/json\/products\/([0-9]+)(.+?)index.json$/)
        pathMatches11 = pathMatches1[2].match(/^\/(.*?)\/(.*?)\/([0-9]+)\/$/)
        prodTypeConfig = this.getProductsType(pathMatches1[1])
      }
      if (path2) {
        pathMatches2 = path2.match(/^\/json\/products\/([0-9]+)(.+?)index.json$/)
        pathMatches21 = pathMatches2[2].match(/^\/(.*?)\/(.*?)\/([0-9]+)\/$/)
      }
      if (prodTypeConfig && pathMatches11 && pathMatches21) {
        if (pathMatches11[3] < pathMatches21[3]) {
          urlpath = `/${prodTypeConfig.paths.home}/${project.contents.product_types.products.paths.comparison}/${pathMatches11[3]}/${pathMatches21[3]}/${pathMatches11[1]}-${pathMatches11[2]}-vs-${pathMatches21[1]}-${pathMatches21[2]}.html`
        } else {
          urlpath = `/${prodTypeConfig.paths.home}/${project.contents.product_types.products.paths.comparison}/${pathMatches21[3]}/${pathMatches11[3]}/${pathMatches21[1]}-${pathMatches21[2]}-vs-${pathMatches11[1]}-${pathMatches11[2]}.html`
        }
      }
      return urlpath
    },
    setProductPriceLink (path) {
      let urlpath = `/${project.contents.product_types.products.paths.home}/${project.contents.product_types.products.paths.product_prices}/${project.contents.product_types.products.paths.prices_all}/`
      if (path) {
        const pathMatches = path.match(/^\/json\/products\/([0-9]+)(.+?)index.json$/)
        if (pathMatches !== null) {
          const prodTypeConfig = this.getProductsType(pathMatches[1])
          if (prodTypeConfig) {
            urlpath = `/${prodTypeConfig.paths.home}${pathMatches[2]}${project.contents.product_types.products.paths.product_prices}/${project.contents.product_types.products.paths.prices_all}/`
          }
        }
      }
      return urlpath
    },
    setRaceLink (path, flag = '') {
      let urlpath = `/${project.contents.races.all.paths.home}/`
      if (path) {
        const pathMatches = path.match(/^\/json\/races\/(.+?)index.json$/)
        urlpath += pathMatches[1]
      }
      if (flag !== '') {
        urlpath += flag
      }
      return urlpath
    },
    setForumLink (path, flag = '') {
      const pathMatches = path.match(/^\/json\/forum\/(.+?).json$/)
      const urlpath = `/${pathMatches[1]}.html`
      return urlpath
    },
    setOpinionLink (path) {
      const urlpath = `/${project.contents.inquiries.all.paths.home}`
      const pathMatches = path.match(/^\/json\/products\/([0-9]+)(.+?)index.json$/)
      const prodTypeConfig = this.getProductsType(pathMatches[1])
      const valores = pathMatches[2].split('/')
      return (
        (prodTypeConfig &&
          `${urlpath}${valores[1]}-${valores[2]}-${valores[3]}/`) ||
        ''
      )
    },
    findText (e, track = false) {
      const finderUrl = `/${project.contents.product_types.products.paths.home}/${project.contents.product_types.products.paths.products}/${project.contents.product_types.products.paths.products_all}/`
      if (this.$route.path !== `${finderUrl}`) {
        this.$router.push(`${finderUrl}?q=` + encodeURIComponent(e.target.value) + (track ? `&o=${track}` : ''))
      } else {
        window.location.href = `${finderUrl}?q=` + encodeURIComponent(e.target.value) + (track ? `&o=${track}` : '')
      }
    },
    findArticle (e, track = false) {
      if (this.$route.path !== `/${project.contents.articles.all.paths.home}/`) {
        this.$router.push(
          `/${project.contents.articles.all.paths.home}/?q=` +
          encodeURIComponent(e.target.value) + (track ? `&o=${track}` : '')
        )
      } else {
        window.location.href =
          `/${project.contents.articles.all.paths.home}/?q=` +
          encodeURIComponent(e.target.value) + (track ? `&o=${track}` : '')
      }
    },
    findRace (e) {
      if (
        this.$route.path !==
        `/${project.contents.races.all.paths.home}/${project.contents.races.all.paths.races}/`
      ) {
        this.$router.push(
          `/${project.contents.races.all.paths.home}/${project.contents.races.all.paths.races}/?q=` +
          encodeURIComponent(e.target.value)
        )
      } else {
        window.location.href =
          `/${project.contents.races.all.paths.home}/${project.contents.races.all.paths.races}/?q=` +
          encodeURIComponent(e.target.value)
      }
    },
    create_filter_url (
      id,
      value,
      filters,
      productType,
      listType,
      location = '',
      qryObj = false
    ) {
      if (location !== '') {
        location = `/${productType.paths.home}/${location}/`.replace(/\/\//g, '/')
      } else {
        location = `/${productType.paths.home}/`
      }
      const listTypeData = listType.split(':')
      listType = listTypeData[0]
      const listTypePath = (listTypeData[1] && ('/' + listTypeData[1])) || ''
      // Url to array
      const urlFilters = {}
      filters.forEach(filter => {
        const filtroConfig = productType.filters.find(f => f.id === filter.id)
        if (filtroConfig) {
          urlFilters[filter.id] = []
          if (filter.active === 1 || filter.id === id) {
            filter.values.forEach(val => {
              if (filter.id === id && val.id === value) {
                if (val.active === 0) {
                  urlFilters[filter.id].push(val.id)
                }
              } else if (val.active === 1) {
                urlFilters[filter.id].push(val.id)
              }
            })
          }
        }
      })
      // Traducir filtros
      const urlFiltersProyecto = {}
      Object.entries(urlFilters).forEach(([filter, values]) => {
        const filtroConfig = productType.filters.find(f => f.id === filter)
        const filtroDefault =
          filtroConfig.default &&
          filtroConfig.default[listType] &&
          filtroConfig.default[listType].value
        const filtroUrlIndex = filtroConfig.url || filtroConfig.name
        if (values.length > 0) {
          urlFiltersProyecto[filtroUrlIndex] = []
          values.forEach(v => {
            if (filtroConfig?.values) {
              const valorConfig = filtroConfig.values.find(
                val => String(val.id) === String(v)
              )
              urlFiltersProyecto[filtroUrlIndex].push(
                valorConfig?.url || valorConfig?.name || v
              )
            } else {
              urlFiltersProyecto[filtroUrlIndex].push(v)
            }
          })
        } else if (values.length <= 0 && filtroDefault) {
          urlFiltersProyecto[filtroUrlIndex] = [filtroDefault]
        }
      })
      // Array to url
      let filters2url = ''
      Object.entries(urlFiltersProyecto).forEach(([filter, values]) => {
        const filtroConfig = productType.filters.find(f => (f.url || f.name) === filter)
        if (filtroConfig.id !== 'type') {
          const filtroPrefix =
            !filtroConfig.prefix || filtroConfig.prefix[listType] !== false
          filters2url +=
            (filtroPrefix && `/${filter}-${values.join(',')}`) || `/${values.join(',')}`
        }
        if (filtroConfig.id === 'type') {
          location = `/${values.join(',')}/`
        }
      })
      const listPath = productType.paths && productType.paths[listType]
      let finalUrl = ''
      finalUrl = `${location}${listPath}${listTypePath}${filters2url}/`.replace(/\/\//g, '/')
      let qryStr = ''
      if (qryObj) {
        qryStr = new URLSearchParams(qryObj).toString()
      }
      if (qryStr.length > 0) {
        finalUrl = finalUrl + '?' + qryStr
      }
      return finalUrl
    },
    getFilterValSeo (filterId, valueID, productType, pill = false) {
      if (filterId === 'brand' && typeof valueID === 'string') {
        const marca = valueID.split('-')
        const salida = marca.map((palabra) => palabra.charAt(0).toUpperCase() + palabra.slice(1))
        return salida.join(' ')
      }
      const filtroConfig = productType.filters.find(f => f.id === filterId)
      if (filtroConfig?.values) {
        const valueConfig = filtroConfig.values.find(v => v.id === valueID)
        if (valueConfig) {
          // cushioning, constitution, footprint
          const seoVal = valueConfig.name
          if (pill && (filtroConfig.id === 'cushioning' || filtroConfig.id === 'constitution' || filtroConfig.id === 'footprint')) {
            return filtroConfig.name.charAt(0).toUpperCase() + filtroConfig.name.slice(1) + ' ' + seoVal.charAt(0).toUpperCase() + seoVal.slice(1)
          }
          return seoVal.charAt(0).toUpperCase() + seoVal.slice(1)
        }
      }
      return valueID
    },
    toggleFilters (filters) {
      this.filterActive = !this.filterActive
      this.filterTypeFilters = filters
    },
    isOverflown (filterid) {
      const elements = this.$refs[filterid]

      if (elements && elements[0]) {
        const element = elements[0]
        return element.scrollWidth > element.clientWidth
      }

      return true
    },
    setFilterType (filter) {
      let f = 'pills'
      switch (filter) {
        case 'colour':
          f = 'pills'
          break
        // default: f = 'checkboxes'; break
        default:
          f = 'pills'
          break
      }
      return f
    },
    formatPrice (price, country = null) {
      return new Intl.NumberFormat(country ? `${country.lang}-${country.iso}` : project.loc_code, { style: 'currency', currency: country ? country.currency : project.currency }).format(price)
    },
    formatDate (date, options = {}) {
      const dateObj = new Date(date)
      return new Intl.DateTimeFormat(project.loc_code, options).format(dateObj)
    },
    capitalize (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    openNewTab (to) {
      const routeData = this.$router.resolve(to)
      window.open(routeData.href, '_blank')
    },
    addProd2Alert (user) {
      if (user) {
        return true
      } else {
        this.createModal(
          '/auth/login',
          project.contents.login.all.texts.alert,
          project.contents.login.all.texts.not_loged,
          project.contents.login.all.texts.go_to_login,
          'alert'
        )
      }
    },
    addProd2Compare (product, type, string = false) {
      this.$store.dispatch('comparator/add', string ? JSON.parse(product) : product)
      this.createModal(
        `/${type}/${project.contents.product_types.products.paths.comparator}/`,
        project.contents.comparator.texts.added_product,
        project.contents.comparator.texts.added_product,
        project.contents.comparator.texts.go_to_comparator
      )
    },
    setCouponLink (coupon) {
      return `/${project.contents.discount_codes.all.paths.home}/?cupon=${coupon.content_id}`
    },
    storeFilters (
      filterID,
      valueID,
      url,
      productType,
      type,
      location,
      active,
      highlight,
      productId = false,
      filters,
      qryObj = false
    ) {
      const filterObj = filters.view.find(f => f.id === filterID)
      this.$store
        .dispatch(active ? 'filters/remove' : 'filters/add', {
          data: type === 'products' ? 'products' : 'prices',
          id: productType.id,
          connotation: highlight ? 'global' : productId || productType.id,
          filter: filterID,
          value: valueID,
          filters: filterObj
        })
        .then(() => {
          if (highlight) {
            this.$store
              .dispatch(active ? 'filters/remove' : 'filters/add', {
                data: type !== 'products' ? 'products' : 'prices',
                id: productType.id,
                connotation: highlight ? 'global' : productId || productType.id,
                filter: filterID,
                value: valueID,
                filters: filterObj
              })
              .then(() => {
                this.$router.push(
                  this.create_filter_url(
                    filterID,
                    valueID,
                    url,
                    productType,
                    type,
                    location,
                    qryObj
                  )
                )
              })
          } else {
            this.$router.push(
              this.create_filter_url(
                filterID,
                valueID,
                url,
                productType,
                type,
                location,
                qryObj
              )
            )
          }
        })
    },
    unescapeHtml (safe) {
      return safe
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&quot;/g, '"')
        .replace(/&#039;/g, "'")
        .replace(/&#8220;/g, '"')
        .replace(/&#8221;/g, '"')
    },
    createModal (url = false, title = false, text = false, button = false, hash = false) {
      const h = this.$createElement
      const messageVNode = h('div', { class: ['foobar'] }, [
        text,
        h(
          'button',
          {
            class: ['btn btn-primary w-100 mt-3'],
            on: {
              click: () => {
                if (hash) {
                  this.$store.dispatch('setLoginRedirect', this.$route.path + '#' + hash)
                }
                this.$router.push(url)
              }
            }
          },
          button
        )
      ])
      this.$bvModal.msgBoxOk([messageVNode], {
        title: `${title}`,
        hideFooter: true,
        footerClass: 'd-none',
        hideHeaderClose: false,
        centered: true,
        size: 'md'
      })
    },
    strCap (str, separator) {
      const splitStr = (str && str.toLowerCase().split(' ')) || []
      for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase()
      }
      return splitStr.join(separator)
    },
    number2text (number, decimals = 0) {
      const numberString = ((decimals && Math.round(number * decimals * 10)) || Math.round(number)).toString()

      return ((numberString.length > decimals) ? numberString.substring(0, numberString.length - decimals) : '0') + (decimals ? '.' + numberString.substring(numberString.length - decimals) : '')
    },
    scoreGroup (score) {
      const productScore = parseInt(score)

      let productGroup = 'groupFive'
      if (productScore >= 9) {
        productGroup = 'groupNine'
      } else if (productScore >= 8) {
        productGroup = 'groupEight'
      } else if (productScore >= 7) {
        productGroup = 'groupSeven'
      } else if (productScore >= 6) {
        productGroup = 'groupSix'
      }

      return productGroup
    },
    getTimeAgo (time) {
      const timeAgo = this.now - time
      if (timeAgo < 3600) {
        const timeAgoMinutes = Math.ceil(timeAgo / 60)
        return this.$t('global.timeAgoMinutes', {
          minutes: timeAgoMinutes
        })
      } else if (timeAgo < 24 * 3600) {
        const timeAgoHours = Math.floor(timeAgo / 3600)
        const timeAgoMinutes = Math.floor((timeAgo % 3600) / 60)
        return this.$t(timeAgoMinutes ? 'global.timeAgoHoursMinutes' : 'global.timeAgoHours', {
          hours: timeAgoHours,
          minutes: timeAgoMinutes
        })
      } else {
        const timeAgoDays = Math.floor(timeAgo / (24 * 3600))
        return this.$t((timeAgoDays === 1) ? 'global.timeAgoDay' : 'global.timeAgoDays', {
          days: timeAgoDays
        })
      }
    },
    setGTMEvent (name, data) {
      this.$gtm && this.$gtm.push({
        event: name,
        ...data
      })
    },
    addDebug (name, data, type = 'log') {
      if (project.enviroment !== 'dev') return
      switch (type) {
        case 'log':
          console.log(name, data)
          break
        case 'info':
          console.info(name, data)
          break
        case 'warn':
          console.warn(name, data)
          break
        case 'error':
          console.error(name, data)
          break
        default:
          console.log(name, data)
          break
      }
    },
    getLocaleDate (date, options = { day: 'numeric', month: 'long', year: 'numeric' }) {
      let localeDate = new Date(date)
      localeDate = localeDate.toLocaleDateString(project.loc_code, options)
      return localeDate
    },
    getLocaleCurrency (price, options = { style: 'currency', currency: project.currency.iso }) {
      if (typeof price === 'string') {
        price = parseFloat(price)
      }
      return price.toLocaleString(project.loc_code, options)
    }
  }

}
