//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LazyHydrate from 'vue-lazy-hydration'
import AxiApi from '~/lib/axi'
import projectData from '~/static/config/project.json'
import * as cssFunctions from '~/mixins/css.functions.js'
import { customPageView } from '~/lib/gtm'

export default {
  components: {
    LazyHydrate
  },
  async asyncData ({ app, params, route, store, error, $axios, $config, $md5 }) {
    // Get props & params
    let page = 1
    if (params.pag && params.pag > 0) {
      page = params.pag
    }
    const routeProps = route.matched[0].props.default
    const TypeId = (routeProps?.prodTypeId > 0) ? routeProps.prodTypeId : 0
    const TypeConfig = Object.values(projectData.contents.product_types).find(p => p.id === TypeId)
    const listFilter = (routeProps?.filter) ? routeProps.filter : false

    // Zones & Heritages
    const ax = new AxiApi($config.api)
    const call = (TypeId && `${$config.static}/json/zones/products/${TypeId}/zones.json`) || ($config.static + '/json/zones/authors/zones.json')
    let zones
    try {
      zones = await $axios.get(call)
    } catch (err) {
      zones = {}
    }

    await ax.createComponentData(zones.data, store)
    // Get API data
    let filters2api = ''
    if (listFilter) {
      switch (listFilter) {
        case 'articles':
          filters2api += '/articles-1'
          break
        case 'reviews':
          filters2api += '/reviews-1'
          break
        default:
          break
      }
    }
    if (TypeId > 0) {
      filters2api += `/product_type-${TypeId}/`
    }
    const query = `${$config.api}/app/v1/authors/list?_p=${$config.id}&filters=${filters2api}&page=${page}`
    const code = $md5(query)
    if (
      typeof store.state.lists[code] === 'undefined' ||
      Object.keys(store.state.lists[code]).length === 0
    ) {
      await store.dispatch('loadLists', query)
    }
    const authors = store.state.lists[code]

    // Structured Data
    const structuredData = {
      '@context': 'https://schema.org',
      '@type': 'ItemList',
      itemListElement: [
      ],
      itemListOrder: 'https://schema.org/ItemListUnordered',
      name: app.i18n.t('author_list.meta_title_articles')
    }
    authors.contents.forEach((author, i) => {
      structuredData.itemListElement.push({
        '@type': 'ListItem',
        position: (i + 1),
        url: `https://www.runnea.com/${projectData.contents.brands.all.paths.home}/#${author.id}`,
        image: `${author.photo}`,
        name: `${author.name}`
      })
    })

    // Metas & Head
    let pageTitle = app.i18n.t('author_list.page_title')
    let metaTitle = app.i18n.t('author_list.meta_title')
    let metaDescription = app.i18n.t('author_list.meta_description')
    if (listFilter) {
      switch (listFilter) {
        case 'articles':
          pageTitle = app.i18n.t(`author_list.page_title_${listFilter}`)
          metaTitle = app.i18n.t(`author_list.meta_title_${listFilter}`)
          metaDescription = app.i18n.t(`author_list.meta_description_${listFilter}`)
          break
        case 'reviews':
          pageTitle = app.i18n.t(`author_list.page_title_${listFilter}`).replace(/{seo}/g, TypeConfig.name.toLowerCase())
          metaTitle = app.i18n.t(`author_list.meta_title_${listFilter}`).replace(/{seo}/g, TypeConfig.name.toLowerCase())
          metaDescription = app.i18n.t(`author_list.meta_description_${listFilter}`)
          break
        default:
          break
      }
    }

    const head = {
      title: `${metaTitle} | ${projectData.name}`,
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: `${metaTitle} | ${projectData.name}`
        },
        {
          hid: 'description',
          name: 'description',
          content: `${metaDescription}`
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: `${$config.domain}${route.path}`
        }
      ],
      script: [
        { type: 'application/ld+json', json: structuredData }
      ]
    }

    if (Object.keys(route.query).length > 0) {
      head.meta.push(
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex, follow'
        }
      )
    }

    // Header
    const header = {
      h1: pageTitle
    }

    const zonesContent = ax.get()

    if (!app.$isAMP) {
      head.style = await cssFunctions.criticals(route.path, 'author_list', {
        ABC_0: zonesContent.GlobalData?.zones?.ABC_0 || 0
      })
    }

    return {
      authors,
      zones: zonesContent,
      head,
      header
    }
  },
  head () {
    return this.head
  },
  mounted () {
    customPageView.apply(this, [this.head.title])
  }
}
