//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import global from '~/mixins/global.js'
import projectData from '~/static/config/project.json'
import { openAuthRequired } from '~/lib/auth'
import AppLink from './AppLink.vue'
Vue.config.silent = true
export default {
  name: 'ModuleFinder',
  components: {
    VueTypeaheadBootstrap,
    AppLink
  },
  mixins: [global],
  inheritAttrs: false,
  props: {
    data: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    product: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    zone: {
      type: String,
      default: ''
    },
    products: {
      type: [Object, Array],
      default: () => [{}, []]
    }
  },
  data () {
    return {
      query: '',
      suggestionsArray: [],
      projectID: projectData.id,
      productsBrands: [],
      productsArray: [],
      articlesArray: [],
      isMobile: false
    }
  },
  beforeMount () {
    if (!this.$store.state.articlesLoaded || !this.$store.state.productsLoaded) {
      this.$store.dispatch('loadProducts').then(() => {
        this.$store.dispatch('loadArticles').then(() => {
          this.productsArray = this.$store.state.products.map(p => {
            return {
              title: p.brand + ' ' + p.title,
              type_id: p.type_id,
              path: p.path,
              photo: p.photo,
              type: 'product',
              search: this.plainText(p.brand + ' ' + p.title),
              prioritySearch: this.plainText(p.brand)
            }
          })
          this.articlesArray = this.$store.state.articles.map(a => {
            return {
              title: a.title,
              path: a.path,
              photo: a.photo,
              type: 'article',
              search: this.plainText(a.title)
            }
          })
        })
      })
    } else {
      this.productsArray = this.$store.state.products.map(p => {
        return {
          title: p.brand + ' ' + p.title,
          type_id: p.type_id,
          path: p.path,
          photo: p.photo,
          type: 'product',
          search: this.plainText(p.brand + ' ' + p.title),
          prioritySearch: this.plainText(p.brand)
        }
      })
      this.articlesArray = this.$store.state.articles.map(a => {
        return {
          title: a.title,
          type_id: a.type_id,
          path: a.path,
          photo: a.photo,
          type: 'article',
          search: this.plainText(a.title)
        }
      })
    }

    this.suggestionsArray = [].concat(this.productsArray, this.articlesArray)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize);
  },
  mounted () {
    this.isMobile = window.innerWidth < 768;
    window.addEventListener('resize', this.onResize);

    if (!this.$store.state.productsLoaded) {
      this.$store.dispatch('loadProducts')
    }
  },
  methods: {
    evento (data) {
      const query = data.target?.value ? data.target.value : data.title
      const tipo = data.target?.value ? 'query' : 'sugerencia'
      this.$gtm && this.$gtm.push({ event: 'ClickFinder', query, origen: 'module', tipo })
    },
    mixSuggestions (array1, array2) {
      const resultado = []
      const maxLength = Math.max(array1.length, array2.length)

      for (let i = 0; i < maxLength; i++) {
        if (i < array1.length) {
          resultado.push(array1[i])
        }
        if (i < array2.length) {
          resultado.push(array2[i])
        }
      }
      return resultado
    },
    findItem (item) {
      if (item.type === 'product') {
        const pathMatches = item.path.match(
          /^\/json\/products\/([0-9]+)(.+?)index.json$/
        )
        const prodTypeConfig = Object.values(projectData.contents.product_types).find(p => p.id === item.type_id)
        if (prodTypeConfig && pathMatches) {
          const url = `/${prodTypeConfig.paths.home}${pathMatches[2]}`
          this.$router.push(url)
        }
      } else if (item.type_id === '15') {
        const pathMatches = item.path.match(
          /^\/json\/articles\/(.+?)index.json$/
        )
        const url = `/${projectData.contents.articles.all.paths.home}/${pathMatches[1]}`
        this.$router.push(url)
      } else if (item.type_id === '60') {
        const pathMatches = item.path.match(
          /^\/json\/galleries\/(.+?)index.json$/
        )
        const url = `/${projectData.contents.galleries.all.paths.home}/${pathMatches[1]}`
        this.$router.push(url)
      }
    },
    onCheckLogin () {
      if (this.$auth.loggedIn) {
        openAuthRequired.apply(this, [
          null,
          {
            name: 'showModal'
          },
          'modal',
          'forumChat'
        ])
      } else {
        openAuthRequired.apply(this, [
          null,
          {
            name: 'showModal'
          },
          'modal',
          'products'
        ])
      }
    },
    plainText (text) {
      const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;'
      const to = 'aaaaaeeeeeiiiiooooouuuunc------'
      from.split('').forEach((character, i) => {
        text = text.replace(new RegExp(character, 'g'), to.charAt(i))
      })
      return text
        .toString()
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/&/g, '-y-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')
    },
    taSearch () {
      if (this.query.length > 1) {
        const query = this.plainText(this.query)
        if (query) {
        // Filtrar productos por prioritySearch y por coincidencia general en search
          const priorityProducts = this.productsArray.filter(p => p.prioritySearch === query)
          const generalProducts = this.productsArray.filter(p =>
            p.prioritySearch !== query && p.search.includes(query)
          )
          // Filtrar artículos por coincidencia general en search
          const filteredArticles = this.articlesArray.filter(a => a.search.includes(query))

          // Combinar y limitar resultados
          const combinedProducts = [...priorityProducts, ...generalProducts].slice(0, 5)
          const combinedArticles = filteredArticles.slice(0, 5)

          // Concatenar resultados
          this.suggestionsArray = [...combinedProducts, ...combinedArticles]
        } else {
          this.suggestionsArray = [
            ...this.productsArray.slice(0, 5),
            ...this.articlesArray.slice(0, 5)
          ]
        }
      }
    },
    onResize () {
      return process.client ? window.innerWidth < 768 : false;
    }
  }
}
