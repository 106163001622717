//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LazyHydrate from 'vue-lazy-hydration'
import AxiApi from '~/lib/axi'
import global from '~/mixins/global.js'
import projectData from '~/static/config/project.json'
import * as cssFunctions from '~/mixins/css.functions.js'
import { customPageView } from '~/lib/gtm'

export default {
  components: {
    LazyHydrate
  },
  mixins: [global],
  async asyncData ({ params, route, app, store, error, $axios, $config }) {
    // Zones & Heritages
    const ax = new AxiApi($config.api)
    const call = $config.static + '/json/zones/discount/zones.json'
    let zones
    try {
      zones = await $axios.get(call)
    } catch (err) {
      return error({ statusCode: 404, message: err.message })
    }
    await ax.createComponentData(zones.data, store)

    // Get JSON data
    if (!store.state.couponsLoaded) {
      await store.dispatch('loadCoupons')
    }
    const codes = store.state.coupons

    // Get Url Coupon
    let showModal = false
    let selectedCoupon = false
    if ((typeof route.query.cupon !== 'undefined' && route.query.cupon !== '') && (selectedCoupon = codes.find(c => c.content_id === route.query.cupon))) {
      showModal = true
    }

    // Metas & Head
    const header = {
      h1: app.i18n.t('discount_codes_list.page_title').replace(/{year}/, (new Date().getFullYear()))
    }
    const head = {
      title: `${app.i18n.t('discount_codes_list.meta_title')} | ${projectData.name}`,
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: `${app.i18n.t('discount_codes_list.meta_title')} | ${projectData.name}`
        },
        {
          hid: 'description',
          name: 'description',
          content: `${app.i18n.t('discount_codes_list.meta_description')}`
        }
      ]
    }

    if (Object.keys(route.query).length > 0) {
      head.meta.push(
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex, follow'
        }
      )
    }

    const zonesContent = ax.get()

    if (!app.$isAMP) {
      head.style = await cssFunctions.criticals(route.path, 'discount_codes_list', {
        ABC_0: zonesContent.GlobalData?.zones?.ABC_0 || 0
      })
    }

    return {
      zones: zonesContent,
      codes,
      head,
      header,
      selectedCoupon: typeof selectedCoupon !== 'undefined' ? selectedCoupon : false,
      showModal
    }
  },
  data () {
    return {
      showModal: false
    }
  },
  head () {
    return this.head
  },
  mounted () {
    customPageView.apply(this, [this.head.title])
  },
  methods: {
    copiarTexto (str, shareTxt, copiedTxt) {
      setTimeout(() => {
        document.getElementById('btn-copiar-cupon').innerHTML = shareTxt
      }, 2000)
      if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        document.getElementById('btn-copiar-cupon').innerHTML = '¡' + copiedTxt + '!'
        return navigator.clipboard.writeText(str)
      } else {
        return false
      }
    },
    tieneCodigo (code) {
      if (code.includes('Ir a la web') || code.includes(' - ')) {
        return false
      } else if (code.includes('Pas de code')) {
        return false
      } else if (code.includes('Senza codice')) {
        return false
      } else {
        return true
      }
    }
  }
}
