//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios'
import global from '~/mixins/global.js'
import AxiApi from '~/lib/axi'
import projectData from '~/static/config/project.json'
import * as cssFunctions from '~/mixins/css.functions.js'
import { openAuthRequired, applyAuthAction } from '~/lib/auth'
export default {
  mixins: [global],
  layout: 'default',
  async asyncData ({ params, route, app, store, error, $axios, $config, $md5 }) {
    if (projectData.id !== 1) {
      error({ statusCode: 404, message: 'Page not found' })
    }

    if (!params.id) {
      route.push('/running-music/list')
    }

    // Zones & Heritages
    const ax = new AxiApi($config.api)
    const call = $config.static + '/json/zones/brands/zones.json'
    const zones = await $axios.get(call)
    await ax.createComponentData(zones.data, store)

    // Structured Data
    const structuredData = {
      '@context': 'https://schema.org',
      '@type': 'ItemList',
      itemListElement: [
      ],
      itemListOrder: 'https://schema.org/ItemListUnordered',
      name: 'Running & Music'
    }

    // Metas & Head
    const pageTitle = 'Running & Music'
    const metaTitle = 'Running & Music'
    const metaDescription = 'Descubre una nueva manera de disfrutar del running con nuestras sesiones con música y guiadas por los mejores entrenadores'

    const head = {
      title: `${metaTitle} | ${projectData.name}`,
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: `${metaTitle} | ${projectData.name}`
        },
        {
          hid: 'description',
          name: 'description',
          content: `${metaDescription}`
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: `${$config.domain}${route.path}`
        }
      ],
      script: [
        { type: 'application/ld+json', json: structuredData }
      ]
    }

    // Header
    const zonesContent = ax.get()

    const header = {
      h1: pageTitle
    }

    if (!app.$isAMP) {
      head.style = await cssFunctions.criticals(route.path, 'audios_page', {
        ABC_0: zonesContent.GlobalData?.zones?.ABC_0 || 0
      })
    }

    return {
      audioId: params.id || false,
      zones: zonesContent,
      head,
      header
    }
  },
  data () {
    return {
      audioList: null,
      audioId: null,
      audioSelect: null
    }
  },
  head () { return this.head },
  mounted () {
    this.loadAudios()
    this.loadAudio()
    applyAuthAction.apply(this)
  },
  methods: {
    async loadAudios () {
      try {
        await axios
          .get('https://contenidos.runnea.academy/audios/sesiones/index.json')
          .then((audios) => {
            this.audioList = audios.data
          })
      } catch (error) {}
    },
    async loadAudio () {
      try {
        await axios
          .get(
            `https://contenidos.runnea.academy/audios/sesiones/${this.audioId}.json`
          )
          .then((audio) => {
            this.audioSelect = audio.data
          })
      } catch (error) {}
    },
    playAudio () {
      const audio = this.$refs.audioElm
      if (audio !== undefined) {
        audio.play()
      } else {
        setTimeout(() => {
          this.playAudio()
        }, 300)
      }
    },
    onPlayCheck () {
      if (this.$auth.loggedIn) {
        this.$refs.audioElm.play()
      } else {
        this.$refs.audioElm.pause()
        openAuthRequired.apply(this, [
          {
            hash: '#audioSeccion'
          },
          {
            name: 'playAudio'
          },
          'modal',
          'audios'
        ])
      }
    }
  }
}
