import Vue from 'vue'

import { md5 } from 'pure-md5'

export const state = () => ({
  // global
  config: {},
  modules: {},
  lists: {},
  // global
  articles: [],
  brands: [],
  stores: [],
  products: [],
  races: [],
  coupons: [],
  prodsPerType: [],
  articlesLoaded: false,
  brandsLoaded: false,
  storesLoaded: false,
  productsLoaded: false,
  racesLoaded: false,
  couponsLoaded: false,
  loginRedirect: false,
  versionsForChangeLang: null
})
export const mutations = {
  SET_CONFIG (state, config) {
    state.config = config
  },
  SET_ARTICLES (state, articles) {
    state.articles = articles
    state.articlesLoaded = true
  },
  SET_BRANDS (state, brands) {
    state.brands = brands
    state.brandsLoaded = true
  },
  SET_STORES (state, stores) {
    state.stores = stores
    state.storesLoaded = true
  },
  SET_PRODUCTS (state, products) {
    state.products = products
    state.productsLoaded = true
  },
  SET_PRODUCTSPERTYPE (state, { products, type }) {
    state.prodsPerType[type] = products
  },
  SET_RACES (state, races) {
    state.races = races
    state.racesLoaded = true
  },
  SET_COUPONS (state, coupons) {
    state.coupons = coupons
    state.couponsLoaded = true
  },
  SET_MODULES (state, { key, value }) {
    Vue.set(state.modules, key, value)
  },
  SET_LISTS (state, { key, value }) {
    Vue.set(state.lists, key, value)
  },
  SET_LOGIN_REDIRECT (state, url) {
    this.$cookiz.set('hash', url)
  },
  SET_VERSIONS_FOR_CHANGE_LANG (state, versions) {
    state.versionsForChangeLang = versions
  },
  GET_LOGIN_REDIRECT (state) {
    state.loginRedirect = this.$cookiz.get('hash') ? this.$cookiz.get('hash') : false
  }
}
export const actions = {
  nuxtServerInit ({ commit }, { $config }) {
    commit('SET_CONFIG', $config)
  },
  setLoginRedirect ({ commit }, url) {
    commit('SET_LOGIN_REDIRECT', url)
  },
  setVersionsForChangeLang ({ commit }, versions) {
    commit('SET_VERSIONS_FOR_CHANGE_LANG', versions)
  },
  getLoginRedirect ({ commit }) {
    commit('GET_LOGIN_REDIRECT')
  },
  delLoginRedirect ({ commit }) {
    commit('SET_LOGIN_REDIRECT', false)
  },
  async loadArticles ({ commit, state }) {
    const response = await this.$axios.get(state.config.static + '/json/articles/index.json')
    commit('SET_ARTICLES', response.data)
  },
  async loadBrands ({ commit, state }) {
    const response = await this.$axios.get(state.config.static + '/json/brands/index.json')
    commit('SET_BRANDS', (typeof response.data === 'object') ? response.data : [])
  },
  async loadStores ({ commit, state }) {
    const response = await this.$axios.get(state.config.static + '/json/stores/index.json')
    commit('SET_STORES', response.data)
  },
  async loadProducts ({ commit, state }) {
    const response = await this.$axios.get(state.config.static + '/json/products/index.json')
    commit('SET_PRODUCTS', response.data)
  },
  async loadProductsPerType ({ commit, state }, type) {
    const response = await this.$axios.get(state.config.static + '/json/products/index.' + type + '.json')
    commit('SET_PRODUCTSPERTYPE', { products: response.data, type })
  },
  async loadRaces ({ commit, state }) {
    const response = await this.$axios.get(state.config.static + '/json/races/index.json')
    commit('SET_RACES', response.data)
  },
  async loadCoupons ({ commit, state }) {
    const response = await this.$axios.get(state.config.static + '/json/discount-codes/index.json')
    commit('SET_COUPONS', response.data)
  },
  async loadModules ({ commit }, call) {
    try {
      const response = await this.$axios.get(call)
      if (response.status === 200) {
        commit('SET_MODULES', { key: md5(call), value: response.data })
      }
    } catch (error) {
    }
  },
  async loadLists ({ commit }, call) {
    try {
      const response = await this.$axios.get(call)
      commit('SET_LISTS', { key: md5(call), value: response.data })
    } catch (error) {
      commit('SET_LISTS', { key: md5(call), value: {} })
    }
  },
  async loadListsError ({ commit }, call) {
    const response = await this.$axios.get(call)
    if (response.status === 200) {
      commit('SET_LISTS', { key: md5(call), value: response.data })
    } else {
      throw new Error('Invalid data API')
    }
  }
}
