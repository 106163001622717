//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LazyHydrate from 'vue-lazy-hydration'
import AxiApi from '~/lib/axi'
import projectData from '~/static/config/project.json'
import * as cssFunctions from '~/mixins/css.functions.js'
import { customPageView } from '~/lib/gtm'

export default {
  components: {
    LazyHydrate
  },
  props: {
    elements: {
      type: [Object, Array],
      default: () => [{}, []]
    }
  },
  async asyncData ({ params, route, app, store, $axios, $md5, $config, error }) {
    const routeProps = route.matched[0].props.default

    const ax = new AxiApi($config.api)
    const zones = await $axios.get(
      $config.static + '/json/zones/races/zones.json'
    )
    await ax.createComponentData(zones.data, store)

    projectData.contents.races.all.filters = projectData.contents.races.all.filters.map((filter) => {
      if (['province', 'region', 'country'].includes(filter.id)) {
        filter.values = filter.values.map((value) => {
          value.url = value.url || value.name
          value.name = value.name.charAt(0).toUpperCase() + value.name.slice(1)
          return value
        })
      }
      return filter
    })

    // Get API data
    let page = 1
    const filters = {}
    const filtersSEO = {}
    let filters2api = ''
    let filters2seo = ''

    if (params.pathMatch && params.pathMatch !== '') {
      let filtersUrl = params.pathMatch
      if (filtersUrl.match(/^(.*?\/)?([0-9]+)\/?$/)) {
        const pathMatches = filtersUrl.match(/^(.*?\/)?([0-9]+)\/?$/)
        page = pathMatches[2]
        filtersUrl = (pathMatches[1] !== undefined) ? pathMatches[1] : ''
      }
      filtersUrl.split('/').forEach((element) => {
        if (element.match(/[a-z]+-[0-9]{4}/)) {
          const filtroFechaValores = element.split('-')
          if (typeof filtroFechaValores[1] !== 'undefined') {
            filters.year = filtroFechaValores[1]
            filtersSEO.year = filtroFechaValores[1]
          }
        } else if (element !== '') {
          let filtroValor
          const filtroConfig = projectData.contents.races.all.filters.find((f) => {
            const filtroValorProyecto = f.values.find(v => (v.url || v.name) === element)
            if (typeof filtroValorProyecto !== 'undefined') {
              filtroValor = filtroValorProyecto
              return true
            }
            return false
          })
          filters[filtroConfig.id] = filtroValor.id
          filtersSEO[filtroConfig.id] = filtroValor.seo || filtroValor.name
        }
      })
      projectData.contents.races.all.filters.forEach((f) => {
        if (filters[f.id]) {
          filters2api += '/' + f.id + '-' + filters[f.id]
          // Dinamic SEO
          const filtroSEO = f.seo || '{value}'
          filters2seo += ` ${filtroSEO.replace('{value}', filtersSEO[f.id] || filters[f.id])}`
        }
      })
    }

    let Text = ''
    if (route.query.q) {
      Text = route.query.q
    }

    const query = `${$config.api}/app/v1/races/list?_p=${$config.id}&filters=${filters2api}&text=${Text}&page=${page}`
    const code = $md5(query)
    if (
      typeof store.state.lists[code] === 'undefined' ||
      Object.keys(store.state.lists[code]).length === 0
    ) {
      await store.dispatch('loadLists', query)
    }
    const raceList = store.state.lists[code]

    // Breadcrumb
    let breadcrumb = []
    breadcrumb = breadcrumb.concat(routeProps.breadcrumb)
    const breadFilters = []
    if (raceList.filters && raceList.filters.view) {
      raceList.filters.view.forEach((filter) => {
        filter.values.forEach((val) => {
          if (val.active === 1) {
            const filterF = projectData.contents.races.all.filters.find(f => f.id === filter.id)
            const filterFV = filterF.values.find(v => v.id === String(val.id))
            if (filterFV?.url || filterFV?.name) {
              breadFilters.push(filterFV.url || filterFV.name)
              breadcrumb.push({
                text: filterFV.name,
                href: `/${projectData.contents.races.all.paths.home}/${projectData.contents.races.all.paths.races}/${breadFilters.join('/')}/`
              })
            }
          }
        })
      })
    }
    if (breadcrumb.length === 1) {
      breadcrumb = []
    }
    if (breadcrumb.length === 0) {
      const filters = `${filters2seo}`.split(' ')
      if (filters.length > 1) {
        breadcrumb.push({
          text: app.i18n.t('race_list.meta_title').split('{seo}')[0],
          href: `/${projectData.contents.races.all.paths.home}/${projectData.contents.races.all.paths.races}/`
        })
      }
      for (let i = 1; i < filters.length; i++) {
        breadFilters.push(filters[i])
        breadcrumb.push({
          text: filters[i],
          href: `/${projectData.contents.races.all.paths.home}/${projectData.contents.races.all.paths.races}/${breadFilters.join('/').replace(breadFilters.join('/').charAt(0), breadFilters.join('/').charAt(0).toLowerCase())}/`
        })
      }
    }
    if (breadcrumb.length > 1) {
      breadcrumb[breadcrumb.length - 1].active = true
    }

    // Structured Data
    const racesStructuredData = []
    if (raceList.contents) {
      raceList.contents.forEach((race) => {
        const item = {
          '@context': 'https://schema.org',
          '@type': 'Event',
          name: race.title,
          description: `${app.i18n.t('race_list.description')} ${race.title}.`,
          image: `${(race.poster?.src) ? race.poster?.src : require('@/assets/img/' + projectData.contents.races.all.images.all.default)}`,
          startDate: `${race.date}T${race.time}`,
          endDate: `${race.date}T${race.time}`,
          location: {
            '@type': 'Place',
            name: `${app.i18n.t('race_list.start')} ${race.title}`,
            address: {
              '@type': 'PostalAddress',
              addressLocality: race.place,
              addressRegion: race.province,
              addressCountry: {
                '@type': 'Country',
                name: race.country
              }
            }
          },
          organizer: {
            '@type': 'Organization',
            name: race.organizer
          }
        }
        racesStructuredData.push(item)
      })
    }
    // Metas & Head
    const dinamicSeo = `${filters2seo}`
    const header = {
      h1: app.i18n.t('race_list.page_title').replace('{seo}', dinamicSeo)
    }
    const head = {
      title: `${app.i18n.t('race_list.meta_title').replace('{seo}', dinamicSeo)} | ${projectData.name}`,
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: `${app.i18n.t('race_list.meta_title').replace('{seo}', dinamicSeo)} | ${projectData.name}`
        },
        {
          hid: 'description',
          name: 'description',
          content: app.i18n.t('race_list.meta_description').replace('{seo}', dinamicSeo)
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: `${$config.domain}${route.path}`
        }
      ],
      script: []
    }

    if (Object.keys(route.query).length > 0) {
      head.meta.push(
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex, follow'
        }
      )
    }
    racesStructuredData.forEach((element) => {
      head.script.push({ type: 'application/ld+json', json: element })
    })

    const zonesContent = ax.get()

    if (!app.$isAMP) {
      head.style = await cssFunctions.criticals(route.path, 'race_list', {
        ABC_0: zonesContent.GlobalData?.zones?.ABC_0 || 0
      })
    }

    return {
      breadcrumb,
      head,
      header,
      zones: zonesContent,
      races: raceList,
      projectData,
      dinamicSeo,
      Text
    }
  },
  data () {
    return {
      iconClose: require('~/assets/img/icons/icon-close.svg')
    }
  },
  head () {
    return this.head
  },
  mounted () {
    customPageView.apply(this, [this.head.title])
  }
}
