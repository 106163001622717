import Vue from 'vue'
import Router from 'vue-router'
import * as scrollFunctions from '~/mixins/scroll.functions.js'
import UserPage from '~/pages/user_page/_.vue'
import AuthorList from '~/pages/author_list/_.vue'
import ArticleList from '~/pages/article_list/_.vue'
import ArticlePag from '~/pages/article_page/_.vue'
import GalleryPag from '~/pages/gallery_page/_.vue'
import Recommender from '~/pages/recommender/_.vue'
import ReviewList from '~/pages/review_list/_.vue'
import BrandList from '~/pages/brand_list/_.vue'
import StorePage from '~/pages/store_page/_.vue'
import RaceList from '~/pages/race_list/_.vue'
import RacePage from '~/pages/race_page/_.vue'
import InquiryPage from '~/pages/inquiry_page/_.vue'
import InquiryList from '~/pages/inquiry_list/_.vue'
import TalkPage from '~/pages/talk_page/_.vue'
import ProductList from '~/pages/product_list/_.vue'
import TagPage from '~/pages/tag_page/_.vue'
import ComparatorPag from '~/pages/comparator_page/_.vue'
import ComparisonPag from '~/pages/comparison_page/_.vue'
import OpinionList from '~/pages/opinion_list/_.vue'
import PriceList from '~/pages/price_list/_.vue'
import ProductVariant from '~/pages/product_variant/_.vue'
import ProductPrices from '~/pages/product_prices/_.vue'
import ProductPag from '~/pages/product_page/_.vue'
import DiscountCodesList from '~/pages/discount_codes_list/_.vue'
import Calculator from '~/pages/calculator/_.vue'
import CalculatorMarathon from '~/pages/calculator_marathon/_.vue'
import CalculatorHalfMarathon from '~/pages/calculator_half_marathon/_.vue'
import Calculator10Km from '~/pages/calculator_10_km/_.vue'
import NotFoundPage from '~/pages/not_found_page/_.vue'
import RedirectingUrlPag from '~/pages/redirecting/tracking_url.vue'
import RedirectingPag from '~/pages/redirecting/_.vue'
import AudiosList from '~/pages/audios_list/_.vue'
import AudiosPage from '~/pages/audios_page/_.vue'
import AuthLogin from '~/pages/auth_login/_.vue'
import AuthRedirect from '~/pages/auth_redirect/_.vue'
import AuthCallback from '~/pages/auth_callback/_.vue'
import AuthData from '~/pages/auth_data/_.vue'
import AuthNewsletter from '~/pages/auth_newsletter/_.vue'
import AuthRemember from '~/pages/auth_remember/_.vue'
import AuthPassword from '~/pages/auth_password/_.vue'
import AuthSignin from '~/pages/auth_signin/_.vue'
import CountriesRedirect from '~/pages/countries_redirect/_.vue'
import LandingPag from '~/pages/landing_page/_.vue'

Vue.use(Router)

export function createRouter () {
  return new Router({
    mode: 'history',
    base: '/',
    scrollBehavior: (to, from) => {
      scrollFunctions.init()

      if (to.hash) {
        scrollFunctions.set(to.hash)
        return false
      }

      return { x: 0, y: 0 }
    },
    routes: [
      {
        path:'/usuario/',
        component:UserPage
      },
      {
        path:'/articulos/autores/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"articles"}
      },
      {
        path:'/articulos/autores/:autor/:pag(\\d+)?',
        component:ArticleList,
        props:{"breadcrumb":[{"text":"autores","href":"/articulos/autores/"}]}
      },
      {
        path:'/articulos/categorias/:categoria/:pag(\\d+)?',
        component:ArticleList,
        props:{"breadcrumb":[{"text":"Articulos","href":"/articulos/"}]}
      },
      {
        path:'/articulos/etiquetas/:etiqueta/:pag(\\d+)?',
        component:ArticleList,
        props:{"article":"etiqueta","filter":"tag","breadcrumb":[{"text":"etiqueta","href":"/articulos/etiquetas/"}]}
      },
      {
        path:'/articulos/:pag(\\d+)?',
        component:ArticleList,
        props:{"breadcrumb":[{"text":"Articulos","href":"/articulos/"}]}
      },
      {
        path:'/articulos/*index.amp.html',
        component:ArticlePag,
        meta:{"amp":true}
      },
      {
        path:'/articulos/*',
        component:ArticlePag
      },
      {
        path:'/galerias/*.amp.html',
        component:GalleryPag,
        meta:{"amp":true}
      },
      {
        path:'/galerias/*.html',
        component:GalleryPag
      },
      {
        path:'/shoe-finder/index.amp.html',
        component:Recommender,
        meta:{"amp":true}
      },
      {
        path:'/shoe-finder/',
        component:Recommender
      },
      {
        path:'/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/reviews/probadores/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/autores/:pag(\\d+)?',
        component:AuthorList
      },
      {
        path:'/marcas/',
        component:BrandList
      },
      {
        path:'/tienda/:tienda/*',
        component:StorePage
      },
      {
        path:'/carreras-populares/calendario/:pathMatch?',
        component:RaceList,
        props:{"breadcrumb":[{"text":"Carreras populares","href":"/carreras-populares/calendario/"}]}
      },
      {
        path:'/carreras-populares/calendario/*',
        component:RaceList,
        props:{"breadcrumb":[{"text":"Carreras populares","href":"/carreras-populares/calendario/"}]}
      },
      {
        path:'/carreras-populares/*/inscripciones.html',
        component:RacePage,
        props:{"tipo":"inscriptions","breadcrumb":[{"text":"Carreras populares","href":"/carreras-populares/calendario/"}]}
      },
      {
        path:'/carreras-populares/*/recorrido.html',
        component:RacePage,
        props:{"tipo":"route","breadcrumb":[{"text":"Carreras populares","href":"/carreras-populares/calendario/"}]}
      },
      {
        path:'/carreras-populares/*/verificar.html',
        component:RacePage,
        props:{"tipo":"verify","breadcrumb":[{"text":"Carreras populares","href":"/carreras-populares/calendario/"}]}
      },
      {
        path:'/carreras-populares/*/foro.html',
        component:RacePage,
        props:{"tipo":"forum","breadcrumb":[{"text":"Carreras populares","href":"/carreras-populares/calendario/"}]}
      },
      {
        path:'/carreras-populares/*',
        component:RacePage,
        props:{"tipo":"","breadcrumb":[{"text":"Carreras populares","href":"/carreras-populares/calendario/"}]}
      },
      {
        path:'/*/consultas/*-:id.html',
        component:InquiryPage,
        props:{"prodTypeId":"1","breadcrumb":[{"text":"/Consultas","href":"/zapatillas-running/consultas/todas/"}]}
      },
      {
        path:'/*/consultas/*/',
        component:InquiryList,
        props:{"breadcrumb":[{"text":"/Consultas","href":"/zapatillas-running/consultas/todas/"}],"sections":"130,131,132,133,30,3,1,87,85,89,83"}
      },
      {
        path:'/comentarios/articulos/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comentarios/articulos/"}]}
      },
      {
        path:'/buscador/modelos/todas/*/:q?',
        component:ProductList,
        props:{"prodTypeId":"0","breadcrumb":[{"text":"modelos","href":"/buscador/modelos/todas/"}]}
      },
      {
        path:'/buscador/modelos/:marca/*/:q?',
        component:ProductList,
        props:{"prodTypeId":"0","breadcrumb":[{"text":"modelos","href":"/buscador/modelos/todas/"}]}
      },
      {
        path:'/zapatillas/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"1,50,62,64,84"}
      },
      {
        path:'/zapatillas/comparador/',
        component:ComparatorPag,
        props:{"prodTypeId":"1,50,62,64,84"}
      },
      {
        path:'/zapatillas/comparativa/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"1,50,62,64,84"}
      },
      {
        path:'/zapatillas/consultas/recomendaciones/*',
        component:InquiryList,
        props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Zapatillas","href":"/zapatillas/modelos/todas/"}],"Type":1}
      },
      {
        path:'/comentarios/zapatillas/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comentarios/zapatillas/"}]}
      },
      {
        path:'/zapatillas/reviews/probadores/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"1,50,62,64,84"}
      },
      {
        path:'/zapatillas/reviews/probadores/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/zapatillas/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/zapatillas/opiniones/todas/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Opiniones","href":"/opiniones/todas/"}]}
      },
      {
        path:'/zapatillas/modelos/todas/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Zapatillas","href":"/zapatillas/modelos/todas/"}]}
      },
      {
        path:'/zapatillas/modelos/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Zapatillas","href":"/zapatillas/modelos/todas/"}]}
      },
      {
        path:'/zapatillas/outlet/todas/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Zapatillas","href":"/zapatillas/modelos/todas/"}]}
      },
      {
        path:'/zapatillas/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Zapatillas","href":"/zapatillas/modelos/todas/"}]}
      },
      {
        path:'/zapatillas/*/modelo/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"1,50,62,64,84"}
      },
      {
        path:'/zapatillas/*/amp/precios/todas/*',
        component:ProductPrices,
        props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Zapatillas","href":"/zapatillas/modelos/todas/"}]},
        meta:{"amp":true}
      },
      {
        path:'/zapatillas/*/precios/todas/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Zapatillas","href":"/zapatillas/modelos/todas/"}]}
      },
      {
        path:'/zapatillas/*/amp/precios/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Zapatillas","href":"/zapatillas/modelos/todas/"}]},
        meta:{"amp":true}
      },
      {
        path:'/zapatillas/*/precios/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Zapatillas","href":"/zapatillas/modelos/todas/"}]}
      },
      {
        path:'/zapatillas/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"1,50,62,64,84"},
        meta:{"amp":true}
      },
      {
        path:'/zapatillas/*',
        component:ProductPag,
        props:{"prodTypeId":"1,50,62,64,84"}
      },
      {
        path:'/zapatillas-running/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"1"}
      },
      {
        path:'/zapatillas-running/comparador/',
        component:ComparatorPag,
        props:{"prodTypeId":"1"}
      },
      {
        path:'/zapatillas-running/comparativa/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"1"}
      },
      {
        path:'/zapatillas-running/consultas/recomendaciones/*',
        component:InquiryList,
        props:{"prodTypeId":"1","breadcrumb":[{"text":"Zapatillas running","href":"/zapatillas-running/modelos/todas/"}],"Type":1}
      },
      {
        path:'/comentarios/zapatillas-running/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comentarios/zapatillas-running/"}]}
      },
      {
        path:'/zapatillas-running/reviews/probadores/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"1"}
      },
      {
        path:'/zapatillas-running/reviews/probadores/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"1","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/zapatillas-running/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"1","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/zapatillas-running/opiniones/todas/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"1","breadcrumb":[{"text":"Opiniones","href":"/opiniones/todas/"}]}
      },
      {
        path:'/zapatillas-running/modelos/todas/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"1","breadcrumb":[{"text":"Zapatillas running","href":"/zapatillas-running/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-running/modelos/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"1","breadcrumb":[{"text":"Zapatillas running","href":"/zapatillas-running/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-running/outlet/todas/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"1","breadcrumb":[{"text":"Zapatillas running","href":"/zapatillas-running/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-running/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"1","breadcrumb":[{"text":"Zapatillas running","href":"/zapatillas-running/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-running/*/modelo/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"1"}
      },
      {
        path:'/zapatillas-running/*/amp/precios/todas/*',
        component:ProductPrices,
        props:{"prodTypeId":"1","breadcrumb":[{"text":"Zapatillas running","href":"/zapatillas-running/modelos/todas/"}]},
        meta:{"amp":true}
      },
      {
        path:'/zapatillas-running/*/precios/todas/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"1","breadcrumb":[{"text":"Zapatillas running","href":"/zapatillas-running/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-running/*/amp/precios/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"1","breadcrumb":[{"text":"Zapatillas running","href":"/zapatillas-running/modelos/todas/"}]},
        meta:{"amp":true}
      },
      {
        path:'/zapatillas-running/*/precios/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"1","breadcrumb":[{"text":"Zapatillas running","href":"/zapatillas-running/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-running/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"1"},
        meta:{"amp":true}
      },
      {
        path:'/zapatillas-running/*',
        component:ProductPag,
        props:{"prodTypeId":"1"}
      },
      {
        path:'/relojes-deportivos/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"45"}
      },
      {
        path:'/relojes-deportivos/comparador/',
        component:ComparatorPag,
        props:{"prodTypeId":"45"}
      },
      {
        path:'/relojes-deportivos/comparativa/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"45"}
      },
      {
        path:'/relojes-deportivos/consultas/recomendaciones/*',
        component:InquiryList,
        props:{"prodTypeId":"45","breadcrumb":[{"text":"Relojes deportivos","href":"/relojes-deportivos/modelos/todas/"}],"Type":1}
      },
      {
        path:'/comentarios/relojes-deportivos/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comentarios/relojes-deportivos/"}]}
      },
      {
        path:'/relojes-deportivos/reviews/probadores/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"45"}
      },
      {
        path:'/relojes-deportivos/reviews/probadores/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"45","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/relojes-deportivos/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"45","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/relojes-deportivos/opiniones/todas/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"45","breadcrumb":[{"text":"Opiniones","href":"/opiniones/todas/"}]}
      },
      {
        path:'/relojes-deportivos/modelos/todas/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"45","breadcrumb":[{"text":"Relojes deportivos","href":"/relojes-deportivos/modelos/todas/"}]}
      },
      {
        path:'/relojes-deportivos/modelos/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"45","breadcrumb":[{"text":"Relojes deportivos","href":"/relojes-deportivos/modelos/todas/"}]}
      },
      {
        path:'/relojes-deportivos/outlet/todas/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"45","breadcrumb":[{"text":"Relojes deportivos","href":"/relojes-deportivos/modelos/todas/"}]}
      },
      {
        path:'/relojes-deportivos/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"45","breadcrumb":[{"text":"Relojes deportivos","href":"/relojes-deportivos/modelos/todas/"}]}
      },
      {
        path:'/relojes-deportivos/*/modelo/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"45"}
      },
      {
        path:'/relojes-deportivos/*/amp/precios/todas/*',
        component:ProductPrices,
        props:{"prodTypeId":"45","breadcrumb":[{"text":"Relojes deportivos","href":"/relojes-deportivos/modelos/todas/"}]},
        meta:{"amp":true}
      },
      {
        path:'/relojes-deportivos/*/precios/todas/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"45","breadcrumb":[{"text":"Relojes deportivos","href":"/relojes-deportivos/modelos/todas/"}]}
      },
      {
        path:'/relojes-deportivos/*/amp/precios/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"45","breadcrumb":[{"text":"Relojes deportivos","href":"/relojes-deportivos/modelos/todas/"}]},
        meta:{"amp":true}
      },
      {
        path:'/relojes-deportivos/*/precios/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"45","breadcrumb":[{"text":"Relojes deportivos","href":"/relojes-deportivos/modelos/todas/"}]}
      },
      {
        path:'/relojes-deportivos/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"45"},
        meta:{"amp":true}
      },
      {
        path:'/relojes-deportivos/*',
        component:ProductPag,
        props:{"prodTypeId":"45"}
      },
      {
        path:'/sneakers/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"50"}
      },
      {
        path:'/sneakers/comparador/',
        component:ComparatorPag,
        props:{"prodTypeId":"50"}
      },
      {
        path:'/sneakers/comparativa/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"50"}
      },
      {
        path:'/sneakers/consultas/recomendaciones/*',
        component:InquiryList,
        props:{"prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modelos/todas/"}],"Type":1}
      },
      {
        path:'/comentarios/sneakers/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comentarios/sneakers/"}]}
      },
      {
        path:'/sneakers/reviews/probadores/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"50"}
      },
      {
        path:'/sneakers/reviews/probadores/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"50","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/sneakers/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"50","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/sneakers/opiniones/todas/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"50","breadcrumb":[{"text":"Opiniones","href":"/opiniones/todas/"}]}
      },
      {
        path:'/sneakers/modelos/todas/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modelos/todas/"}]}
      },
      {
        path:'/sneakers/modelos/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modelos/todas/"}]}
      },
      {
        path:'/sneakers/outlet/todas/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modelos/todas/"}]}
      },
      {
        path:'/sneakers/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modelos/todas/"}]}
      },
      {
        path:'/sneakers/*/modelo/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"50"}
      },
      {
        path:'/sneakers/*/amp/precios/todas/*',
        component:ProductPrices,
        props:{"prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modelos/todas/"}]},
        meta:{"amp":true}
      },
      {
        path:'/sneakers/*/precios/todas/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modelos/todas/"}]}
      },
      {
        path:'/sneakers/*/amp/precios/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modelos/todas/"}]},
        meta:{"amp":true}
      },
      {
        path:'/sneakers/*/precios/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modelos/todas/"}]}
      },
      {
        path:'/sneakers/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"50"},
        meta:{"amp":true}
      },
      {
        path:'/sneakers/*',
        component:ProductPag,
        props:{"prodTypeId":"50"}
      },
      {
        path:'/zapatillas-fitness/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"62"}
      },
      {
        path:'/zapatillas-fitness/comparador/',
        component:ComparatorPag,
        props:{"prodTypeId":"62"}
      },
      {
        path:'/zapatillas-fitness/comparativa/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"62"}
      },
      {
        path:'/zapatillas-fitness/consultas/recomendaciones/*',
        component:InquiryList,
        props:{"prodTypeId":"62","breadcrumb":[{"text":"Zapatillas fitness","href":"/zapatillas-fitness/modelos/todas/"}],"Type":1}
      },
      {
        path:'/comentarios/zapatillas-fitness/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comentarios/zapatillas-fitness/"}]}
      },
      {
        path:'/zapatillas-fitness/reviews/probadores/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"62"}
      },
      {
        path:'/zapatillas-fitness/reviews/probadores/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"62","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/zapatillas-fitness/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"62","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/zapatillas-fitness/opiniones/todas/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"62","breadcrumb":[{"text":"Opiniones","href":"/opiniones/todas/"}]}
      },
      {
        path:'/zapatillas-fitness/modelos/todas/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"62","breadcrumb":[{"text":"Zapatillas fitness","href":"/zapatillas-fitness/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-fitness/modelos/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"62","breadcrumb":[{"text":"Zapatillas fitness","href":"/zapatillas-fitness/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-fitness/outlet/todas/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"62","breadcrumb":[{"text":"Zapatillas fitness","href":"/zapatillas-fitness/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-fitness/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"62","breadcrumb":[{"text":"Zapatillas fitness","href":"/zapatillas-fitness/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-fitness/*/modelo/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"62"}
      },
      {
        path:'/zapatillas-fitness/*/amp/precios/todas/*',
        component:ProductPrices,
        props:{"prodTypeId":"62","breadcrumb":[{"text":"Zapatillas fitness","href":"/zapatillas-fitness/modelos/todas/"}]},
        meta:{"amp":true}
      },
      {
        path:'/zapatillas-fitness/*/precios/todas/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"62","breadcrumb":[{"text":"Zapatillas fitness","href":"/zapatillas-fitness/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-fitness/*/amp/precios/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"62","breadcrumb":[{"text":"Zapatillas fitness","href":"/zapatillas-fitness/modelos/todas/"}]},
        meta:{"amp":true}
      },
      {
        path:'/zapatillas-fitness/*/precios/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"62","breadcrumb":[{"text":"Zapatillas fitness","href":"/zapatillas-fitness/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-fitness/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"62"},
        meta:{"amp":true}
      },
      {
        path:'/zapatillas-fitness/*',
        component:ProductPag,
        props:{"prodTypeId":"62"}
      },
      {
        path:'/zapatillas-crossfit/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"64"}
      },
      {
        path:'/zapatillas-crossfit/comparador/',
        component:ComparatorPag,
        props:{"prodTypeId":"64"}
      },
      {
        path:'/zapatillas-crossfit/comparativa/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"64"}
      },
      {
        path:'/zapatillas-crossfit/consultas/recomendaciones/*',
        component:InquiryList,
        props:{"prodTypeId":"64","breadcrumb":[{"text":"Zapatillas crossfit","href":"/zapatillas-crossfit/modelos/todas/"}],"Type":1}
      },
      {
        path:'/comentarios/zapatillas-crossfit/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comentarios/zapatillas-crossfit/"}]}
      },
      {
        path:'/zapatillas-crossfit/reviews/probadores/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"64"}
      },
      {
        path:'/zapatillas-crossfit/reviews/probadores/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"64","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/zapatillas-crossfit/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"64","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/zapatillas-crossfit/opiniones/todas/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"64","breadcrumb":[{"text":"Opiniones","href":"/opiniones/todas/"}]}
      },
      {
        path:'/zapatillas-crossfit/modelos/todas/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"64","breadcrumb":[{"text":"Zapatillas crossfit","href":"/zapatillas-crossfit/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-crossfit/modelos/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"64","breadcrumb":[{"text":"Zapatillas crossfit","href":"/zapatillas-crossfit/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-crossfit/outlet/todas/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"64","breadcrumb":[{"text":"Zapatillas crossfit","href":"/zapatillas-crossfit/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-crossfit/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"64","breadcrumb":[{"text":"Zapatillas crossfit","href":"/zapatillas-crossfit/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-crossfit/*/modelo/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"64"}
      },
      {
        path:'/zapatillas-crossfit/*/amp/precios/todas/*',
        component:ProductPrices,
        props:{"prodTypeId":"64","breadcrumb":[{"text":"Zapatillas crossfit","href":"/zapatillas-crossfit/modelos/todas/"}]},
        meta:{"amp":true}
      },
      {
        path:'/zapatillas-crossfit/*/precios/todas/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"64","breadcrumb":[{"text":"Zapatillas crossfit","href":"/zapatillas-crossfit/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-crossfit/*/amp/precios/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"64","breadcrumb":[{"text":"Zapatillas crossfit","href":"/zapatillas-crossfit/modelos/todas/"}]},
        meta:{"amp":true}
      },
      {
        path:'/zapatillas-crossfit/*/precios/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"64","breadcrumb":[{"text":"Zapatillas crossfit","href":"/zapatillas-crossfit/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-crossfit/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"64"},
        meta:{"amp":true}
      },
      {
        path:'/zapatillas-crossfit/*',
        component:ProductPag,
        props:{"prodTypeId":"64"}
      },
      {
        path:'/zapatillas-spinning/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"66"}
      },
      {
        path:'/zapatillas-spinning/comparador/',
        component:ComparatorPag,
        props:{"prodTypeId":"66"}
      },
      {
        path:'/zapatillas-spinning/comparativa/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"66"}
      },
      {
        path:'/zapatillas-spinning/consultas/recomendaciones/*',
        component:InquiryList,
        props:{"prodTypeId":"66","breadcrumb":[{"text":"Zapatillas spinning","href":"/zapatillas-spinning/modelos/todas/"}],"Type":1}
      },
      {
        path:'/comentarios/zapatillas-spinning/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comentarios/zapatillas-spinning/"}]}
      },
      {
        path:'/zapatillas-spinning/reviews/probadores/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"66"}
      },
      {
        path:'/zapatillas-spinning/reviews/probadores/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"66","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/zapatillas-spinning/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"66","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/zapatillas-spinning/opiniones/todas/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"66","breadcrumb":[{"text":"Opiniones","href":"/opiniones/todas/"}]}
      },
      {
        path:'/zapatillas-spinning/modelos/todas/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"66","breadcrumb":[{"text":"Zapatillas spinning","href":"/zapatillas-spinning/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-spinning/modelos/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"66","breadcrumb":[{"text":"Zapatillas spinning","href":"/zapatillas-spinning/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-spinning/outlet/todas/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"66","breadcrumb":[{"text":"Zapatillas spinning","href":"/zapatillas-spinning/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-spinning/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"66","breadcrumb":[{"text":"Zapatillas spinning","href":"/zapatillas-spinning/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-spinning/*/modelo/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"66"}
      },
      {
        path:'/zapatillas-spinning/*/amp/precios/todas/*',
        component:ProductPrices,
        props:{"prodTypeId":"66","breadcrumb":[{"text":"Zapatillas spinning","href":"/zapatillas-spinning/modelos/todas/"}]},
        meta:{"amp":true}
      },
      {
        path:'/zapatillas-spinning/*/precios/todas/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"66","breadcrumb":[{"text":"Zapatillas spinning","href":"/zapatillas-spinning/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-spinning/*/amp/precios/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"66","breadcrumb":[{"text":"Zapatillas spinning","href":"/zapatillas-spinning/modelos/todas/"}]},
        meta:{"amp":true}
      },
      {
        path:'/zapatillas-spinning/*/precios/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"66","breadcrumb":[{"text":"Zapatillas spinning","href":"/zapatillas-spinning/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-spinning/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"66"},
        meta:{"amp":true}
      },
      {
        path:'/zapatillas-spinning/*',
        component:ProductPag,
        props:{"prodTypeId":"66"}
      },
      {
        path:'/pulseras-actividad/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"73"}
      },
      {
        path:'/pulseras-actividad/comparador/',
        component:ComparatorPag,
        props:{"prodTypeId":"73"}
      },
      {
        path:'/pulseras-actividad/comparativa/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"73"}
      },
      {
        path:'/pulseras-actividad/consultas/recomendaciones/*',
        component:InquiryList,
        props:{"prodTypeId":"73","breadcrumb":[{"text":"Pulseras de actividad","href":"/pulseras-actividad/modelos/todas/"}],"Type":1}
      },
      {
        path:'/comentarios/pulseras-actividad/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comentarios/pulseras-actividad/"}]}
      },
      {
        path:'/pulseras-actividad/reviews/probadores/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"73"}
      },
      {
        path:'/pulseras-actividad/reviews/probadores/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"73","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/pulseras-actividad/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"73","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/pulseras-actividad/opiniones/todas/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"73","breadcrumb":[{"text":"Opiniones","href":"/opiniones/todas/"}]}
      },
      {
        path:'/pulseras-actividad/modelos/todas/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"73","breadcrumb":[{"text":"Pulseras de actividad","href":"/pulseras-actividad/modelos/todas/"}]}
      },
      {
        path:'/pulseras-actividad/modelos/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"73","breadcrumb":[{"text":"Pulseras de actividad","href":"/pulseras-actividad/modelos/todas/"}]}
      },
      {
        path:'/pulseras-actividad/outlet/todas/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"73","breadcrumb":[{"text":"Pulseras de actividad","href":"/pulseras-actividad/modelos/todas/"}]}
      },
      {
        path:'/pulseras-actividad/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"73","breadcrumb":[{"text":"Pulseras de actividad","href":"/pulseras-actividad/modelos/todas/"}]}
      },
      {
        path:'/pulseras-actividad/*/modelo/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"73"}
      },
      {
        path:'/pulseras-actividad/*/amp/precios/todas/*',
        component:ProductPrices,
        props:{"prodTypeId":"73","breadcrumb":[{"text":"Pulseras de actividad","href":"/pulseras-actividad/modelos/todas/"}]},
        meta:{"amp":true}
      },
      {
        path:'/pulseras-actividad/*/precios/todas/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"73","breadcrumb":[{"text":"Pulseras de actividad","href":"/pulseras-actividad/modelos/todas/"}]}
      },
      {
        path:'/pulseras-actividad/*/amp/precios/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"73","breadcrumb":[{"text":"Pulseras de actividad","href":"/pulseras-actividad/modelos/todas/"}]},
        meta:{"amp":true}
      },
      {
        path:'/pulseras-actividad/*/precios/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"73","breadcrumb":[{"text":"Pulseras de actividad","href":"/pulseras-actividad/modelos/todas/"}]}
      },
      {
        path:'/pulseras-actividad/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"73"},
        meta:{"amp":true}
      },
      {
        path:'/pulseras-actividad/*',
        component:ProductPag,
        props:{"prodTypeId":"73"}
      },
      {
        path:'/smartwatch/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"74"}
      },
      {
        path:'/smartwatch/comparador/',
        component:ComparatorPag,
        props:{"prodTypeId":"74"}
      },
      {
        path:'/smartwatch/comparativa/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"74"}
      },
      {
        path:'/smartwatch/consultas/recomendaciones/*',
        component:InquiryList,
        props:{"prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelos/todas/"}],"Type":1}
      },
      {
        path:'/comentarios/smartwatch/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comentarios/smartwatch/"}]}
      },
      {
        path:'/smartwatch/reviews/probadores/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"74"}
      },
      {
        path:'/smartwatch/reviews/probadores/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"74","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/smartwatch/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"74","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/smartwatch/opiniones/todas/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"74","breadcrumb":[{"text":"Opiniones","href":"/opiniones/todas/"}]}
      },
      {
        path:'/smartwatch/modelos/todas/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelos/todas/"}]}
      },
      {
        path:'/smartwatch/modelos/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelos/todas/"}]}
      },
      {
        path:'/smartwatch/outlet/todas/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelos/todas/"}]}
      },
      {
        path:'/smartwatch/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelos/todas/"}]}
      },
      {
        path:'/smartwatch/*/modelo/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"74"}
      },
      {
        path:'/smartwatch/*/amp/precios/todas/*',
        component:ProductPrices,
        props:{"prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelos/todas/"}]},
        meta:{"amp":true}
      },
      {
        path:'/smartwatch/*/precios/todas/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelos/todas/"}]}
      },
      {
        path:'/smartwatch/*/amp/precios/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelos/todas/"}]},
        meta:{"amp":true}
      },
      {
        path:'/smartwatch/*/precios/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelos/todas/"}]}
      },
      {
        path:'/smartwatch/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"74"},
        meta:{"amp":true}
      },
      {
        path:'/smartwatch/*',
        component:ProductPag,
        props:{"prodTypeId":"74"}
      },
      {
        path:'/ciclocomputadores/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"75"}
      },
      {
        path:'/ciclocomputadores/comparador/',
        component:ComparatorPag,
        props:{"prodTypeId":"75"}
      },
      {
        path:'/ciclocomputadores/comparativa/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"75"}
      },
      {
        path:'/ciclocomputadores/consultas/recomendaciones/*',
        component:InquiryList,
        props:{"prodTypeId":"75","breadcrumb":[{"text":"Ciclocomputadores","href":"/ciclocomputadores/modelos/todas/"}],"Type":1}
      },
      {
        path:'/comentarios/ciclocomputadores/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comentarios/ciclocomputadores/"}]}
      },
      {
        path:'/ciclocomputadores/reviews/probadores/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"75"}
      },
      {
        path:'/ciclocomputadores/reviews/probadores/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"75","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/ciclocomputadores/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"75","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/ciclocomputadores/opiniones/todas/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"75","breadcrumb":[{"text":"Opiniones","href":"/opiniones/todas/"}]}
      },
      {
        path:'/ciclocomputadores/modelos/todas/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"75","breadcrumb":[{"text":"Ciclocomputadores","href":"/ciclocomputadores/modelos/todas/"}]}
      },
      {
        path:'/ciclocomputadores/modelos/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"75","breadcrumb":[{"text":"Ciclocomputadores","href":"/ciclocomputadores/modelos/todas/"}]}
      },
      {
        path:'/ciclocomputadores/outlet/todas/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"75","breadcrumb":[{"text":"Ciclocomputadores","href":"/ciclocomputadores/modelos/todas/"}]}
      },
      {
        path:'/ciclocomputadores/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"75","breadcrumb":[{"text":"Ciclocomputadores","href":"/ciclocomputadores/modelos/todas/"}]}
      },
      {
        path:'/ciclocomputadores/*/modelo/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"75"}
      },
      {
        path:'/ciclocomputadores/*/amp/precios/todas/*',
        component:ProductPrices,
        props:{"prodTypeId":"75","breadcrumb":[{"text":"Ciclocomputadores","href":"/ciclocomputadores/modelos/todas/"}]},
        meta:{"amp":true}
      },
      {
        path:'/ciclocomputadores/*/precios/todas/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"75","breadcrumb":[{"text":"Ciclocomputadores","href":"/ciclocomputadores/modelos/todas/"}]}
      },
      {
        path:'/ciclocomputadores/*/amp/precios/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"75","breadcrumb":[{"text":"Ciclocomputadores","href":"/ciclocomputadores/modelos/todas/"}]},
        meta:{"amp":true}
      },
      {
        path:'/ciclocomputadores/*/precios/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"75","breadcrumb":[{"text":"Ciclocomputadores","href":"/ciclocomputadores/modelos/todas/"}]}
      },
      {
        path:'/ciclocomputadores/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"75"},
        meta:{"amp":true}
      },
      {
        path:'/ciclocomputadores/*',
        component:ProductPag,
        props:{"prodTypeId":"75"}
      },
      {
        path:'/zapatillas-trekking/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"84"}
      },
      {
        path:'/zapatillas-trekking/comparador/',
        component:ComparatorPag,
        props:{"prodTypeId":"84"}
      },
      {
        path:'/zapatillas-trekking/comparativa/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"84"}
      },
      {
        path:'/zapatillas-trekking/consultas/recomendaciones/*',
        component:InquiryList,
        props:{"prodTypeId":"84","breadcrumb":[{"text":"Zapatillas trekking","href":"/zapatillas-trekking/modelos/todas/"}],"Type":1}
      },
      {
        path:'/comentarios/zapatillas-trekking/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comentarios/zapatillas-trekking/"}]}
      },
      {
        path:'/zapatillas-trekking/reviews/probadores/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"84"}
      },
      {
        path:'/zapatillas-trekking/reviews/probadores/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"84","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/zapatillas-trekking/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"84","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/zapatillas-trekking/opiniones/todas/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"84","breadcrumb":[{"text":"Opiniones","href":"/opiniones/todas/"}]}
      },
      {
        path:'/zapatillas-trekking/modelos/todas/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"84","breadcrumb":[{"text":"Zapatillas trekking","href":"/zapatillas-trekking/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-trekking/modelos/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"84","breadcrumb":[{"text":"Zapatillas trekking","href":"/zapatillas-trekking/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-trekking/outlet/todas/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"84","breadcrumb":[{"text":"Zapatillas trekking","href":"/zapatillas-trekking/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-trekking/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"84","breadcrumb":[{"text":"Zapatillas trekking","href":"/zapatillas-trekking/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-trekking/*/modelo/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"84"}
      },
      {
        path:'/zapatillas-trekking/*/amp/precios/todas/*',
        component:ProductPrices,
        props:{"prodTypeId":"84","breadcrumb":[{"text":"Zapatillas trekking","href":"/zapatillas-trekking/modelos/todas/"}]},
        meta:{"amp":true}
      },
      {
        path:'/zapatillas-trekking/*/precios/todas/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"84","breadcrumb":[{"text":"Zapatillas trekking","href":"/zapatillas-trekking/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-trekking/*/amp/precios/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"84","breadcrumb":[{"text":"Zapatillas trekking","href":"/zapatillas-trekking/modelos/todas/"}]},
        meta:{"amp":true}
      },
      {
        path:'/zapatillas-trekking/*/precios/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"84","breadcrumb":[{"text":"Zapatillas trekking","href":"/zapatillas-trekking/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-trekking/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"84"},
        meta:{"amp":true}
      },
      {
        path:'/zapatillas-trekking/*',
        component:ProductPag,
        props:{"prodTypeId":"84"}
      },
      {
        path:'/botas-montana/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"1041"}
      },
      {
        path:'/botas-montana/comparador/',
        component:ComparatorPag,
        props:{"prodTypeId":"1041"}
      },
      {
        path:'/botas-montana/comparativa/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"1041"}
      },
      {
        path:'/botas-montana/consultas/recomendaciones/*',
        component:InquiryList,
        props:{"prodTypeId":"1041","breadcrumb":[{"text":"Botas de montaña","href":"/botas-montana/modelos/todas/"}],"Type":1}
      },
      {
        path:'/comentarios/botas-montana/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comentarios/botas-montana/"}]}
      },
      {
        path:'/botas-montana/reviews/probadores/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"1041"}
      },
      {
        path:'/botas-montana/reviews/probadores/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"1041","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/botas-montana/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"1041","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/botas-montana/opiniones/todas/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"1041","breadcrumb":[{"text":"Opiniones","href":"/opiniones/todas/"}]}
      },
      {
        path:'/botas-montana/modelos/todas/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"1041","breadcrumb":[{"text":"Botas de montaña","href":"/botas-montana/modelos/todas/"}]}
      },
      {
        path:'/botas-montana/modelos/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"1041","breadcrumb":[{"text":"Botas de montaña","href":"/botas-montana/modelos/todas/"}]}
      },
      {
        path:'/botas-montana/outlet/todas/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"1041","breadcrumb":[{"text":"Botas de montaña","href":"/botas-montana/modelos/todas/"}]}
      },
      {
        path:'/botas-montana/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"1041","breadcrumb":[{"text":"Botas de montaña","href":"/botas-montana/modelos/todas/"}]}
      },
      {
        path:'/botas-montana/*/modelo/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"1041"}
      },
      {
        path:'/botas-montana/*/amp/precios/todas/*',
        component:ProductPrices,
        props:{"prodTypeId":"1041","breadcrumb":[{"text":"Botas de montaña","href":"/botas-montana/modelos/todas/"}]},
        meta:{"amp":true}
      },
      {
        path:'/botas-montana/*/precios/todas/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"1041","breadcrumb":[{"text":"Botas de montaña","href":"/botas-montana/modelos/todas/"}]}
      },
      {
        path:'/botas-montana/*/amp/precios/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"1041","breadcrumb":[{"text":"Botas de montaña","href":"/botas-montana/modelos/todas/"}]},
        meta:{"amp":true}
      },
      {
        path:'/botas-montana/*/precios/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"1041","breadcrumb":[{"text":"Botas de montaña","href":"/botas-montana/modelos/todas/"}]}
      },
      {
        path:'/botas-montana/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"1041"},
        meta:{"amp":true}
      },
      {
        path:'/botas-montana/*',
        component:ProductPag,
        props:{"prodTypeId":"1041"}
      },
      {
        path:'/zapatillas-padel/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"48"}
      },
      {
        path:'/zapatillas-padel/comparador/',
        component:ComparatorPag,
        props:{"prodTypeId":"48"}
      },
      {
        path:'/zapatillas-padel/comparativa/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"48"}
      },
      {
        path:'/zapatillas-padel/consultas/recomendaciones/*',
        component:InquiryList,
        props:{"prodTypeId":"48","breadcrumb":[{"text":"Zapatillas de pádel","href":"/zapatillas-padel/modelos/todas/"}],"Type":1}
      },
      {
        path:'/comentarios/zapatillas-padel/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comentarios/zapatillas-padel/"}]}
      },
      {
        path:'/zapatillas-padel/reviews/probadores/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"48"}
      },
      {
        path:'/zapatillas-padel/reviews/probadores/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"48","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/zapatillas-padel/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"48","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/zapatillas-padel/opiniones/todas/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"48","breadcrumb":[{"text":"Opiniones","href":"/opiniones/todas/"}]}
      },
      {
        path:'/zapatillas-padel/modelos/todas/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"48","breadcrumb":[{"text":"Zapatillas de pádel","href":"/zapatillas-padel/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-padel/modelos/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"48","breadcrumb":[{"text":"Zapatillas de pádel","href":"/zapatillas-padel/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-padel/outlet/todas/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"48","breadcrumb":[{"text":"Zapatillas de pádel","href":"/zapatillas-padel/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-padel/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"48","breadcrumb":[{"text":"Zapatillas de pádel","href":"/zapatillas-padel/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-padel/*/modelo/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"48"}
      },
      {
        path:'/zapatillas-padel/*/amp/precios/todas/*',
        component:ProductPrices,
        props:{"prodTypeId":"48","breadcrumb":[{"text":"Zapatillas de pádel","href":"/zapatillas-padel/modelos/todas/"}]},
        meta:{"amp":true}
      },
      {
        path:'/zapatillas-padel/*/precios/todas/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"48","breadcrumb":[{"text":"Zapatillas de pádel","href":"/zapatillas-padel/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-padel/*/amp/precios/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"48","breadcrumb":[{"text":"Zapatillas de pádel","href":"/zapatillas-padel/modelos/todas/"}]},
        meta:{"amp":true}
      },
      {
        path:'/zapatillas-padel/*/precios/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"48","breadcrumb":[{"text":"Zapatillas de pádel","href":"/zapatillas-padel/modelos/todas/"}]}
      },
      {
        path:'/zapatillas-padel/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"48"},
        meta:{"amp":true}
      },
      {
        path:'/zapatillas-padel/*',
        component:ProductPag,
        props:{"prodTypeId":"48"}
      },
      {
        path:'/sujetadores-deportivos/outlet/todas/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"58","breadcrumb":[{"text":"Sujetadores deportivos","href":"/sujetadores-deportivos/outlet/todas/"}]}
      },
      {
        path:'/sujetadores-deportivos/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"58","breadcrumb":[{"text":"Sujetadores deportivos","href":"/sujetadores-deportivos/outlet/todas/"}]}
      },
      {
        path:'/mallas-running/outlet/todas/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"71","breadcrumb":[{"text":"Mallas running","href":"/mallas-running/outlet/todas/"}]}
      },
      {
        path:'/mallas-running/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"71","breadcrumb":[{"text":"Mallas running","href":"/mallas-running/outlet/todas/"}]}
      },
      {
        path:'/suplementos-deportivos/outlet/todas/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"72","breadcrumb":[{"text":"Suplementos deportivos","href":"/suplementos-deportivos/outlet/todas/"}]}
      },
      {
        path:'/suplementos-deportivos/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"72","breadcrumb":[{"text":"Suplementos deportivos","href":"/suplementos-deportivos/outlet/todas/"}]}
      },
      {
        path:'/auriculares-bluetooth-inalambricos/outlet/todas/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"76","breadcrumb":[{"text":"Auriculares deportivos","href":"/auriculares-bluetooth-inalambricos/outlet/todas/"}]}
      },
      {
        path:'/auriculares-bluetooth-inalambricos/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"76","breadcrumb":[{"text":"Auriculares deportivos","href":"/auriculares-bluetooth-inalambricos/outlet/todas/"}]}
      },
      {
        path:'/gafas-sol/outlet/todas/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"102","breadcrumb":[{"text":"Gafas de sol","href":"/gafas-sol/outlet/todas/"}]}
      },
      {
        path:'/gafas-sol/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"102","breadcrumb":[{"text":"Gafas de sol","href":"/gafas-sol/outlet/todas/"}]}
      },
      {
        path:'/maquinas-fitness/todas/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"105","breadcrumb":[{"text":"Máquinas de fitness","href":"/maquinas-fitness/todas/"}]}
      },
      {
        path:'/maquinas-fitness/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"105","breadcrumb":[{"text":"Máquinas de fitness","href":"/maquinas-fitness/todas/"}]}
      },
      {
        path:'/cupones-descuento/',
        component:DiscountCodesList
      },
      {
        path:'/calculadoras/calculadora-de-tiempos/',
        component:Calculator
      },
      {
        path:'/calculadoras/calculadora-de-tiempos/maraton/',
        component:CalculatorMarathon
      },
      {
        path:'/calculadoras/calculadora-de-tiempos/media-maraton/',
        component:CalculatorHalfMarathon
      },
      {
        path:'/calculadoras/calculadora-de-tiempos/10-km/',
        component:Calculator10Km
      },
      {
        path:'/pagina-no-encontrada',
        component:NotFoundPage
      },
      {
        path:'/404.html',
        component:NotFoundPage
      },
      {
        path:'/url/enlace/',
        component:RedirectingUrlPag
      },
      {
        path:'/enlace/',
        component:RedirectingPag
      },
      {
        path:'/*/enlace/',
        component:RedirectingPag
      },
      {
        path:'/running-music/list',
        component:AudiosList
      },
      {
        path:'/running-music/playlist/:id',
        component:AudiosPage
      },
      {
        path:'/auth/login',
        component:AuthLogin
      },
      {
        path:'/auth/redirect',
        component:AuthRedirect
      },
      {
        path:'/auth/callback',
        component:AuthCallback
      },
      {
        path:'/auth/data',
        component:AuthData
      },
      {
        path:'/auth/newsletter',
        component:AuthNewsletter
      },
      {
        path:'/auth/remember',
        component:AuthRemember
      },
      {
        path:'/auth/password/:token',
        component:AuthPassword
      },
      {
        path:'/auth/signin',
        component:AuthSignin
      },
      {
        path:'/countries/:country',
        component:CountriesRedirect
      },
      {
        path:'/*',
        component:LandingPag
      }
    ]
  })
}
